<!-- qa搜索模块 -->
<!-- 引入这个模块需要有pagedata -->
<template>
  <div class="searchModelBody">
    <div class="searchBox">
      <h1>Centro de ayuda de Rakumart</h1>
      <div class="searchBoxCon">
        <el-popover
          popper-class="qasearchHistoryBox"
          placement="bottom"
          trigger="manual"
          v-model="visible"
        >
          <ul class="searchHistory">
            <li
              v-for="(item, index) in showSearchList"
              :key="index"
              @click="searchStr = item.text"
            >
              {{ item.text }}
            </li>
          </ul>
          <input
            v-model="searchStr"
            slot="reference"
            type="text"
            @focus="showSearchList.length ? (visible = true) : ''"
            @focusout="visible = false"
            @input="piPei"
            @keydown.enter.prevent="toResultsPage"
            placeholder="Escribe tu pregunta o las palabras clave"
          />
        </el-popover>
        <img
          v-if="!!searchStr"
          @click.prevent="searchStr = ''"
          class="clearInput"
          src="../../../../assets/foot-img/centro-de-ayuda/cha.png"
          alt=""
        />
        <button @click.prevent="toResultsPage">
          <img :src="require('@/assets/icon/sousuo.png')" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchStr: "",
      visible: false, //是否显示搜索列表弹出框
      showSearchList: [],
    };
  },
  components: {},
  computed: {
    // 为了避免重复引入pageData使用父组件里的pagedata
    pageData() {
      return this.$parent.pageData;
    },
  },
  created() {
    // 假如路由带有s参数代表在搜索页面,显示正在搜索的关键词
    if (this.$route.query.s) {
      this.searchStr = this.$route.query.s.replace("+", " ");
    }
  },
  methods: {
    // 输入字符时匹配搜索历史
    piPei() {
      this.showSearchList = [];
      this.$store.state.QASearchHistore.forEach((element) => {
        if (element.text.indexOf(this.searchStr.trim()) != -1) {
          this.showSearchList.unshift(element);
        }
      });
      this.showSearchList = this.showSearchList.slice(0, 6);
      this.visible = false;
      setTimeout(() => {
        if (this.showSearchList.length) {
          this.visible = true;
        }
      }, 100);
    },
    // 跳转搜索结果页面
    toResultsPage() {
      // 如果没有重复的就存起来
      if (
        (this.$store.state.QASearchHistore,
        !this.$store.state.QASearchHistore.find((item) => {
          return item.text == this.searchStr.trim();
        }))
      ) {
        // 把搜索的词语存起来给搜索历史模块使用
        this.$store.commit(
          "getQASearchHistore",
          this.$store.state.QASearchHistore.unshift({
            text: this.searchStr.trim(),
          })
        );
      }
      // 防止打开登录弹窗,
      this.$store.commit("getshowLoginDialog", false);
      // 把搜索字符串里的空格转换为加号(因为示例网站有这样转换)
      let searchStrJia = this.searchStr.trim().replace(" ", "+");
      // 因为西班牙方面设计有搜索结果和没有搜索结果是两个页面,所以在这里先查找有没有结果,再跳转相应的页面,如果在搜索结果页面跳转有可能会被谷歌判断为重定向报错
      let searchArr = searchStrJia.split("+");
      let jumpResults = "resultado-busqueda-no-encontrado";
      searchArr.forEach((strArr) => {
        if (
          this.pageData.findIndex((item) => {
            return (
              (item.title.indexOf(strArr) != -1 ||
                item.answer.indexOf(strArr) != -1 ||
                item.content.indexOf(strArr) != -1) &&
              item.menuLevel != 1
            ); //利用qa首页的menuLevel把qa首页排除出搜索结果
          }) != -1
        ) {
          jumpResults = "resultado-de-busqueda";
        }
      });
      this.$fun.routerToPage(
        "/" + jumpResults + "?s=" + encodeURIComponent(searchStrJia)
      );
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.searchModelBody {
  margin-top: -20px;
  width: 100%;
  height: 276px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f6f7;
  .searchBox {
    h1 {
      text-align: center;
      padding-bottom: 45px;
      font-size: 26px;
      line-height: 1;
    }
    .searchBoxCon {
      display: flex;
      position: relative;

      input {
        width: 700px;
        background-color: white;
        flex: 1;
        height: 65px;
        border: none;
        padding: 0 86px 0 21px;
        font-size: 15px;
        transform: 0.4s;
        border-radius: 6px;
        box-shadow: 0px 3px 4px -1px rgba(0, 0, 0, 0.14);
        &:focus {
          box-shadow: 0px 3px 12px -1px rgba(0, 0, 0, 0.14);
        }
      }

      .clearInput {
        position: absolute;
        right: 75px;
        top: 50%;
        transform: translateY(-50%);
        display: none;
        cursor: pointer;
      }
      &:hover .clearInput {
        display: block;
      }
      button {
        position: absolute;
        right: 0;
        height: 100%;
        width: 65px;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 17px;
          height: 18px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.qasearchHistoryBox {
  padding: 0;
  .searchHistory {
    min-width: 110px;
    max-width: 500px;

    li {
      line-height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 10px;
      cursor: pointer;
      &:hover {
        background-color: #e8e8e9;
      }
    }
  }
}
</style>
