let data = [
  // QA首页
  {
    pageName: "Centro de ayuda",
    router: "centro-de-ayuda",
    title: "",
    answer: "",
    content: `
        <div class="centro-de-ayuda-page">
          <div class="row">
            <div class="popularAnswers">
              <h1>Preguntas principales:</h1>
              <p name="myClick" @click="$fun.routerToPage('/como-funciona-rakumart')">
                ¿Cómo funciona Rakumart?
              </p>
              <p name="myClick" @click="$fun.routerToPage('/que-ventajas-ofrece-rakumart')">
                ¿Qué ventajas ofrece Rakumart?
              </p>
              <p name="myClick" @click="$fun.routerToPage('/que-garantias-ofrece-rakumart')">
                ¿Qué garantías ofrece Rakumart?
              </p>
              <p name="myClick" @click="$fun.routerToPage('/como-hacer-un-pedido')">
                ¿Cómo hacer un pedido?
              </p>
            </div>
            <div class="popularAnswers">
              <h1>Sobre tus pedidos:</h1>
              <p name="myClick" @click="$fun.routerToPage('/donde-esta-mi-pedido')">
                ¿Dónde está mi pedido?
              </p>
              <p name="myClick" @click="$fun.routerToPage('/cuando-recibo-la-cotizacion')">
                ¿Cuándo recibo la cotización?
              </p>       
              <p name="myClick" @click="$fun.routerToPage('/hay-cantidad-minima-de-compra')">
              ¿Hay cantidad mínima de compra? 
            </p>      
              <p name="myClick" @click="$fun.routerToPage('/puedo-comprar-muestras')">
                ¿Puedo comprar muestras?
              </p>
            </div>
          </div>
          <div class="row">
            <div
              class="categorizedList"
              name="myClick" @click="$fun.routerToPage('/empieza-en-rakumart')"
            >
              <img
                src="https://span-rakumart.oss-eu-central-1.aliyuncs.com/202303/6410131e2a5f3.png"
                alt=""
              />
              <p>Empieza en Rakumart</p>
            </div>
            <div
              class="categorizedList"
              name="myClick" @click="$fun.routerToPage('/mi-cuenta-3')"
            >
              <img
                src="https://span-rakumart.oss-eu-central-1.aliyuncs.com/202303/64101374454fc.png"
                alt=""
              />
              <p>Mi cuenta</p>
            </div>
            <div
              class="categorizedList"
              name="myClick" @click="$fun.routerToPage('/hacer-un-pedido')"
            >
              <img
                src="https://span-rakumart.oss-eu-central-1.aliyuncs.com/202303/641012d3c8a42.png"
                alt=""
              />
              <p>Hacer un pedido</p>
            </div>
            <div class="categorizedList" name="myClick" @click="$fun.routerToPage('/pagos')">
              <img
                src="https://span-rakumart.oss-eu-central-1.aliyuncs.com/202303/6410125de6913.png"
                alt=""
              />
              <p>Pagos</p>
            </div>
            <div
              class="categorizedList"
              name="myClick" @click="$fun.routerToPage('/logistica-y-envio')"
            >
              <img
                src="https://span-rakumart.oss-eu-central-1.aliyuncs.com/202303/641012a3b347f.png"
                alt=""
              />
              <p>Logística y envíos</p>
            </div>
            <div
              class="categorizedList"
              name="myClick" @click="$fun.routerToPage('/garantias-y-posventa')"
            >
              <img
                src="https://span-rakumart.oss-eu-central-1.aliyuncs.com/202303/6410133325b44.png"
                alt=""
              />
              <p>Garantías y posventa</p>
            </div>
          </div>
        </div>
        `,
    menu: "",
    menuLevel: 1,
  },
  // Empieza en Rakumart
  {
    pageName: "Empieza en Rakumart",
    router: "empieza-en-rakumart",
    title: "Empieza en Rakumart",
    answer:
      "Conoce más a fondo Rakumart, cómo funciona y en qué puede mejorar tu negocio importando desde China.",
    content: `
    <ul class="pageListLink">
    <li name="myClick" @click="$fun.routerToPage('/que-hace-rakumart')" >¿Qué es Rakumart?</li>
    <li name="myClick" @click="$fun.routerToPage('/que-es-rakumart')">¿Qué hace Rakumart?</li>
    <li name="myClick" @click="$fun.routerToPage('/como-funciona-rakumart')">¿Cómo funciona Rakumart?</li>
    <li name="myClick" @click="$fun.routerToPage('/que-ventajas-ofrece-rakumart')">¿Qué ventajas ofrece Rakumart?</li>
    <li name="myClick" @click="$fun.routerToPage('/a-quien-ofreceis-vuestros-servicios')">¿A quién va dirigido nuestro servicio?</li>
    <li name="myClick" @click="$fun.routerToPage('/servicios-adicionales-para-productos')">Servicios adicionales para productos</li>
    <li name="myClick" @click="$fun.routerToPage('/servicios-de-personalizacion-y-marca-privada')">Servicios de personalización y marca privada</li>
  </ul>
        `,
    menu: "Empieza en Rakumart",
    menuLevel: 2,
  },
  // Mi cuenta
  {
    pageName: "Mi cuenta",
    router: "mi-cuenta-3",
    title: "Mi cuenta",
    answer:
      "Conoce las preguntas más frecuentes y gestiona todos los detalles de tu cuenta en rakumart.es.",
    content: `
    <ul class="pageListLink">
    <li name="myClick" @click="$fun.routerToPage('/como-me-registro')">¿Cómo me registro?</li>
    <li name="myClick" @click="$fun.routerToPage('/por-que-no-puedo-iniciar-sesion-en-mi-cuenta')">¿Por qué no puedo iniciar sesión en mi cuenta?</li>
    <li name="myClick" @click="$fun.routerToPage('/como-cambio-los-detalles-de-mi-cuenta')">¿Cómo cambio los detalles de mi cuenta?</li>
    <li name="myClick" @click="$fun.routerToPage('/como-editar-o-restablecer-mi-contrasena')">¿Cómo editar o restablecer mi contraseña?</li>
    <li name="myClick" @click="$fun.routerToPage('/donde-puedo-descargar-mis-facturas')">¿Dónde puedo descargar mis facturas?</li>
    <li name="myClick" @click="$fun.routerToPage('/como-puedo-modificar-los-datos-de-facturacion')">¿Cómo puedo modificar los datos de facturación?</li>
    <li name="myClick" @click="$fun.routerToPage('/como-puedo-anadir-o-editar-mi-direccion-de-envio')">¿Cómo puedo añadir o editar mi dirección de envío?</li>
    <li name="myClick" @click="$fun.routerToPage('/donde-puedo-encontrar-los-cupones-que-obtengo')">¿Dónde puedo encontrar los cupones que obtengo?</li>
    <li name="myClick" @click="$fun.routerToPage('/como-usar-un-cupon')">¿Cómo usar un cupón?</li>
  </ul>
        `,
    menu: "Mi cuenta",
    menuLevel: 2,
  },
  // Hacer un pedido
  {
    pageName: "Hacer un pedido",
    router: "hacer-un-pedido",
    title: "Hacer un pedido",
    answer:
      "Conoce las preguntas más frecuentes acerca del funcionamiento de pedidos en rakumart.es.",
    content: `
    <ul class="pageListLink">
    <li name="myClick" @click="$fun.routerToPage('/necesito-una-cuenta-para-hacer-un-pedido')">¿Necesito una cuenta para hacer un pedido?</li>
    <li name="myClick" @click="$fun.routerToPage('/como-hacer-un-pedido')">¿Cómo hacer un pedido?</li>
    <li name="myClick" @click="$fun.routerToPage('/puedo-comprar-muestras')">¿Puedo comprar muestras?</li>
    <li name="myClick" @click="$fun.routerToPage('/cuando-recibo-la-cotizacion')">¿Cuándo recibo la cotización?</li>
    <li name="myClick" @click="$fun.routerToPage('/como-consultar-el-estado-de-mi-pedido')">¿Cómo consultar el estado de mi pedido?</li>
    <li name="myClick" @click="$fun.routerToPage('/que-significa-pedido-pendiente-de-cotizacion')">¿Qué significa “Pedido pendiente de cotización”?</li>
    <li name="myClick" @click="$fun.routerToPage('/que-significa-pedido-de-productos-pendiente-de-pago')">¿Qué significa “Pedido de productos pendiente de pago”?</li>
    <li name="myClick" @click="$fun.routerToPage('/que-significa-envio-internacional-pendiente-de-pago')">¿Qué significa “Envío internacional pendiente de pago”?</li>
    <li name="myClick" @click="$fun.routerToPage('/hay-cantidad-minima-de-compra')">¿Hay cantidad mínima de compra?</li>
    <li name="myClick" @click="$fun.routerToPage('/puedo-hacer-cambios-en-el-pedido-que-hice')">¿Puedo hacer cambios en el pedido que hice?</li>
    <li name="myClick" @click="$fun.routerToPage('/por-que-no-recibi-un-correo-electronico-de-confirmacion-sobre-mi-pedido')">Por qué no recibí un correo electrónico de confirmación sobre mi pedido</li>
    <li name="myClick" @click="$fun.routerToPage('/cual-es-el-significado-de-los-diferentes-estados-de-pedido')">¿Cuál es el significado de los diferentes estados de pedido?</li>
    <li name="myClick" @click="$fun.routerToPage('/como-agregar-articulos-a-un-pedido-confirmado')">¿Cómo agregar artículos a un pedido confirmado?</li>
    <li name="myClick" @click="$fun.routerToPage('/como-cancelar-el-pedido')">¿Cómo cancelar el pedido?</li>
  </ul>
        `,
    menu: "Hacer un pedido",
    menuLevel: 2,
  },
  // Pagos
  {
    pageName: "Pagos",
    router: "pagos",
    title: "Pagos",
    answer:
      "Conoce la información sobre el proceso de pago en Rakumart, así como los requisitos y condiciones.",
    content: `
    <ul class="pageListLink">
    <li name="myClick" @click="$fun.routerToPage('/proceso-de-pagos')">Proceso de pagos</li>
    <li name="myClick" @click="$fun.routerToPage('/como-puedo-realizar-el-pago-de-los-productos')">¿Cómo puedo realizar el pago de un pedido?</li>    
    <li name="myClick" @click="$fun.routerToPage('/como-puedo-realizar-el-pago-del-envio')">¿Cómo puedo realizar el pago del envío?</li>
    <li name="myClick" @click="$fun.routerToPage('/cuales-son-las-tarifas-de-la-compra-de-productos')">¿Cuáles son las tarifas de la compra de productos?</li>
    <li name="myClick" @click="$fun.routerToPage('/debo-pagar-otras-tarifas-cuando-llegue-el-pedido')">¿Debo pagar otras tarifas cuando llegue el pedido?</li>
    <li name="myClick" @click="$fun.routerToPage('/debo-pagar-el-control-de-calidad')">¿Debo pagar el control de calidad?</li>
    <li name="myClick" @click="$fun.routerToPage('/que-tipo-de-pagos-se-aceptan')">¿Qué tipo de pagos se aceptan?</li>
    <li name="myClick" @click="$fun.routerToPage('/que-divisas-puedo-usar')">¿Qué divisas puedo usar?</li> 
  </ul>
        `,
    menu: "Pagos",
    menuLevel: 2,
  },
  // Logística y envíos
  {
    pageName: "Logística y envíos",
    router: "logistica-y-envio",
    title: "Logística y envíos",
    answer:
      "Consulta las preguntas más frecuentes sobre la logística y los envíos en Rakumart.",
    content: `
    <ul class="pageListLink">
    <li name="myClick" @click="$fun.routerToPage('/cual-es-el-coste-del-envio')">¿Cuál es el coste del envío?</li>
    <li name="myClick" @click="$fun.routerToPage('/cuanto-tardare-en-recibir-mi-pedido')">¿Cuánto tardaré en recibir mi pedido?</li>
    <li name="myClick" @click="$fun.routerToPage('/donde-esta-mi-pedido')">¿Dónde está mi pedido?</li>
    <li name="myClick" @click="$fun.routerToPage('/cuando-puedo-obtener-el-numero-de-seguimiento')">¿Cuándo puedo obtener el número de seguimiento?</li>
    <li name="myClick" @click="$fun.routerToPage('/desglose-de-costes-de-envio')">Desglose de costes de envío</li>
    <li name="myClick" @click="$fun.routerToPage('/transporte-de-mercancias-peligrosas')">Transporte de mercancías peligrosas</li>
    <li name="myClick" @click="$fun.routerToPage('/seleccion-de-empresas-de-transporte-internacional')">Selección de empresas de transporte internacional</li>
    <li name="myClick" @click="$fun.routerToPage('/despacho-de-las-mercancias-en-aduanas')">Despacho de las mercancías en aduanas</li>
    <li name="myClick" @click="$fun.routerToPage('/donde-enviais')">¿Dónde enviáis?</li>
    <li name="myClick" @click="$fun.routerToPage('/por-que-no-recibi-un-correo-electronico-sobre-el-envio-de-mi-pedido')">¿Por qué no recibí un correo electrónico sobre el envío de mi pedido?</li>
    <li name="myClick" @click="$fun.routerToPage('/por-que-se-retrasa-mi-pedido')">¿Por qué se retrasa mi pedido?</li>
    <li name="myClick" @click="$fun.routerToPage('/por-que-no-hay-actualizacion-de-seguimiento')">¿Por qué no hay actualización de seguimiento?</li>
    <li name="myClick" @click="$fun.routerToPage('/cuales-son-las-diferencias-entre-los-tres-tipos-de-envio-disponibles')">¿Cuáles son las diferencias entre los tres tipos de envío disponibles?</li>
    <li name="myClick" @click="$fun.routerToPage('/puedo-enviar-mi-pedido-directamente-a-las-bodegas-de-amazon')">¿Puedo enviar mi pedido directamente a las bodegas de Amazon?</li>
  </ul>
        `,
    menu: "Logística y envíos",
    menuLevel: 2,
  },
  // Garantías y posventa
  {
    pageName: "Garantías y posventa",
    router: "garantias-y-posventa",
    title: "Garantías y posventa",
    answer: "Conoce las garantías de productos y servicio que ofrece Rakumart.",
    content: `
    <ul class="pageListLink">
    <li name="myClick" @click="$fun.routerToPage('/que-garantias-ofrece-rakumart')">¿Qué garantías ofrece Rakumart?</li>
    <li name="myClick" @click="$fun.routerToPage('/politica-de-muestras-en-rakumart')">Política de muestras en Rakumart</li>
    <li name="myClick" @click="$fun.routerToPage('/haceis-control-de-calidad-de-la-mercancia')">¿Hacéis control de calidad de la mercancía?</li>
    <li name="myClick" @click="$fun.routerToPage('/que-pasa-si-los-productos-no-superan-el-control-de-calidad')">¿Qué pasa si los productos no superan el control de calidad?</li>
    <li name="myClick" @click="$fun.routerToPage('/como-verificais-a-los-proveedores')">¿Cómo verificáis a los proveedores?</li>
    <li name="myClick" @click="$fun.routerToPage('/los-productos-cuentan-con-los-certificados-necesarios-para-cumplir-con-las-normativas-de-europa')">¿Los productos cuentan con los certificados necesarios para cumplir con las normativas de Europa?</li>
    <li name="myClick" @click="$fun.routerToPage('/que-pasa-si-recibi-un-articulo-defectuoso')">¿Qué pasa si recibí un artículo defectuoso?</li>
    <li name="myClick" @click="$fun.routerToPage('/que-pasa-si-el-pedido-llega-en-mal-estado-a-espana')">¿Qué pasa si el pedido llega en mal estado a España?</li>
    <li name="myClick" @click="$fun.routerToPage('/cual-es-su-politica-de-devolucion-y-reembolso')">¿Cuál es la política de devolución y reembolso?</li>    
    </ul>
        `,
    menu: "Garantías y posventa",
    menuLevel: 2,
  },

  // ¿Qué es Rakumart?
  {
    pageName: "¿Qué es Rakumart?",
    router: "que-hace-rakumart",
    title: '<div class="showBodyBoxTitle">¿Qué es Rakumart?</div>',
    answer: `<div  class="showBodyBoxAnswer">Rakumart permite que los usuarios españoles puedan disfrutar de un fácil acceso a servicios de importación “todo en uno” que van desde la compra, la realización de pedidos, la inspección de calidad, el envío internacional y la posventa en idioma español.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      Con sede central ubicada en Yiwu, China, contamos con un
      equipo independiente de investigación y desarrollo
      tecnológico, con más de 2.500m2 de espacio operativo en
      oficinas, 70.000m2 de almacén en las empresas de nuestro
      grupo y más de 220 empleados en plantilla.
    </p>
    <p>
      Actualmente Rakumart opera en tres continentes cuyas empresa
      afiliadas están situadas en Barcelona (España), Osaka
      (Japón), Seúl (Corea de Sur), Sao Paulo (Brasil), Hong Kong
      y Hangzhou (China).
    </p>
    <p>
      Rakumart cuenta con una
      <b> plataforma de importación todo en uno</b> donde
      conectamos a nuestros clientes directamente al catálogo de
      miles de proveedores chinos, accediendo a los mismos
      productos con precios más competitivos y donde gestionamos
      todo el proceso de compra, logística e importación.
    </p>
  </div>
        `,
    menu: "Empieza en Rakumart",
    menuLevel: 3,
  },
  // ¿Qué hace Rakumart?
  {
    pageName: "¿Qué hace Rakumart?",
    router: "que-es-rakumart",
    title: '<div class="showBodyBoxTitle"> ¿Qué hace Rakumart?</div>',
    answer: `<div  class="showBodyBoxAnswer">Rakumart es una empresa <b>B2B</b> (Business to Business) cuyo objetivo principal es ayudar a los empresarios españoles a importar productos desde China de la forma más económica y eficiente, eliminando costes, procesos y dando al cliente la mejor experiencia de importación.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      El trabajo de Rakumart abarca todas las etapas del proceso
      de importación de productos desde China:
    </p>
    <ul class="listFont">
      <li>
        Permite a los usuarios acceder y buscar entre el catálogo
        de miles de proveedores Chinos.
      </li>
      <li>
        Calcula todos los costes relacionados con la mercancía y
        la importación.
      </li>
      <li>
        Rakumart gestiona todas las necesidades relacionadas con
        el producto, como certificados y personalización. También
        realiza servicios adicionales como fotografía, etiquetado,
        embalaje… (para saber más sobre los servicios especiales,
        haz clic en este enlace).
      </li>
      <li>
        Realiza los pedidos y pagos directamente con el proveedor.
      </li>
      <li>
        Gestiona y desarrolla toda la logística de la mercancía en
        China, desde origen en fábrica hasta la salida del país.
      </li>
      <li>
        Realiza controles de calidad de la mercancía y las
        manipulaciones necesarias para la importación.
      </li>
      <li>
        Rakumart realiza toda la gestión documentaria, trámites y
        procesos aduaneros.
      </li>
      <li>
        Se encarga de recibir, almacenar y enviar la mercancía
        desde nuestros almacenes en Barcelona hasta la dirección
        de entrega del cliente.
      </li>
      <li>
        Proporciona acompañamiento y asesoramiento durante todo el
        proceso, directamente con nuestro equipo de España.
      </li>
    </ul>
  </div>
        `,
    menu: "Empieza en Rakumart",
    menuLevel: 3,
  },

  // ¿Cómo funciona Rakumart?
  {
    pageName: "¿Cómo funciona Rakumart?",
    router: "como-funciona-rakumart",
    title: '<div class="showBodyBoxTitle">¿Cómo funciona Rakumart?</div>',
    answer: `<div  class="showBodyBoxAnswer">Importar con Rakumart es fácil y seguro. Te conectamos directamente al mercado mayorista más grande mundo y gestionamos por ti todas las etapas de compra e importación de tus productos.</div>`,
    content: `
    <div class="allFontPage como-funciona-rakumart">
    <p>
      El funcionamiento y proceso de compra en
      <a href="http://rakumart.es">rakumart.es</a> es el
      siguiente:
    </p>
    <ul class="listFont">
      <li>
        <b>Selección de productos:</b> Busca y agrega los
        productos que deseas comprar al carrito y empieza un nuevo
        pedido.
      </li>
      <li>
        <b>Solicitar cotización:</b> Añade requisitos o servicios
        adicionales si es necesario y solicita una cotización para
        los productos seleccionados. Nuestro equipo se pondrá en
        contacto con el proveedor y obtendrá los datos necesarios
        para cotizar tu pedido.
      </li>
      <li>
      <b>Cotización y pago del pedido</b>: En 24 horas recibes la cotización de los productos y la cotización  de los gastos de envío para cada método de transporte (aéreo, marítimo, terrestre y ferroviario). Elige el método de transporte y realiza el pago.
      </li>
      <li>
        <b>Control de calidad y manipulación</b>: La mercancía de tu pedido es transportada desde fábrica hasta nuestros almacenes en China donde realizamos un control de calidad estándar, realizamos servicios adicionales si es necesario y empacamos todo el pedido.
      </li>
      <li>
        <b>Envío e importación</b>: La mercancía se envía a España donde despachamos y realizamos todos los trámites en aduanas, después la mercancía se prepara en nuestros almacenes en España.
      </li>
      <li>
        <b>Entrega</b>: Nuestro partner logístico (GLS) recoge y realiza la entrega del pedido en tu dirección de envío. Una vez realizada la entrega el pedido se marcará como “Recibido”.
      </li>
    </ul>
    <p>
      Puedes visitar nuestra guía rápida de usuario para más
      detalles:
    </p>
    <p>
      <a href="https://www.rakumart.es/guia-de-usuario"
        >Ver guía rápida de usuario</a
      >
    </p>
  </div>
          `,
    menu: "Empieza en Rakumart", //二级分类名字
    menuLevel: 3,
  },

  // ¿Qué ventajas ofrece Rakumart?
  {
    pageName: "¿Qué ventajas ofrece Rakumart?",
    router: "que-ventajas-ofrece-rakumart",
    title: '<div class="showBodyBoxTitle">¿Qué ventajas ofrece Rakumart?</div>',
    answer: `<div  class="showBodyBoxAnswer">Rakumart proporciona una gran ventaja competitiva a nuestros clientes al simplificar las compras e importaciones desde China y optimizar todos los costes. Nosotros gestionamos todo, desde fábrica hasta la entrega, lo que aumenta la rentabilidad y el tiempo para sus negocios al mismo tiempo.</div>`,
    content: `
    <div class="allFontPage">
    <h3>
      Encontrar todos los productos con precios más económicos.
    </h3>
    <p>
      Rakumart provee a sus clientes la posibilidad de encontrar
      todos los productos que necesitan a precios más económicos.
      Esto se debe a que trabajamos directamente con los
      fabricantes, eliminando intermediarios y reduciendo los
      costes.
    </p>
    <h3>
      Comprar a diferentes proveedores en un mismo pedido y envío.
    </h3>
    <p>
      Olvídate de hablar con varios proveedores a la vez y de
      pagar varias importaciones. En Rakumart somos nosotros
      quienes hacemos el trabajo sucio. Gestionamos todas las
      necesidades de tus productos y preparamos toda tu mercancía
      en un solo envío internacional.
    </p>
    <h3>Optimizar los costes de envío e importación.</h3>
    <p>
      Rakumart optimiza los costes de envío e importación, lo que
      permite a nuestros clientes ahorrar en costes relacionados
      con precio de envío/producto.
    </p>
    <h3>
      Rakumart te brinda toda su infraestructura en China para
      sacarle provecho con tu negocio.
    </h3>
    <p>
      Nuestros clientes pueden disfrutar todo el proceso de compra
      e importación desde un mismo sitio. Sourcing, compra,
      personalización, servicios adicionales, control de calidad,
      logística, ¡Todo en uno!
    </p>
    <h3>
      Tener logística, almacenamiento y control de calidad
      incluido.
    </h3>
    <p>
      Equipo nativo en China, gran experiencia en sourcing y
      compra a fabricantes, logística en China y en España con más
      de 70.000m2 de almacén en las empresas de nuestro grupo.
    </p>
    <p>
      Aprovecha nuestra capacidad operativa, logística y almacenes
      en China sin costes añadidos, ponemos a tu disposición todos
      nuestros todos recursos.
    </p>
    <h3>
      Disfrutar de la gestión completa de la importación hasta tu
      puerta (gestión documentaria, trámites y procesos
      aduaneros).
    </h3>
    <p>
      Con Rakumart, nuestros clientes pueden disfrutar de la
      gestión completa de la importación hasta su puerta. Nos
      encargamos de la gestión documentaria, trámites y procesos
      aduaneros, lo que garantiza la llegada de los productos sin
      problemas y sin retrasos.
    </p>
    <h3>
      Contar con acompañamiento, asesoramiento, servicio de
      atención al cliente y posventa en español.
    </h3>
    <p>
      En Rakumart, puedes contar con acompañamiento, asesoramiento
      y servicio de atención al cliente y posventa en español.
      Esto garantiza que nuestros clientes puedan resolver
      cualquier problema o duda que tengan de manera rápida y
      eficaz en su mismo idioma.
    </p>
    <h3>Garantía de Rakumart España</h3>
    <p>
      La garantía de que todas las operaciones son con una empresa
      española. Nuestros clientes compran su mercancía a una
      empresa con sede y facturación en España, ya que Rakumart se
      encarga de realizar la compra y la importación directamente
      desde China. Esto permite que nuestros clientes se
      beneficien de la seguridad y garantía que esto representa.
    </p>
    <p>
      Conoce más sobre las garantías de Rakumart haciendo
      <a href="/que-garantias-ofrece-rakumart">clic aquí.</a>
    </p>
  </div>
          `,
    menu: "Empieza en Rakumart", //二级分类名字
    menuLevel: 3,
  },

  // ¿A quién va dirigido nuestro servicio?
  {
    pageName: "¿A quién va dirigido nuestro servicio?",
    router: "a-quien-ofreceis-vuestros-servicios",
    title:
      '<div class="showBodyBoxTitle">¿A quién va dirigido nuestro servicio?</div>',
    answer: `<div  class="showBodyBoxAnswer">Rakumart es una plataforma enfocada en el mercado mayorista Chino, que ofrece a todos los <b>negocios, empresas y profesionales autónomos</b> la oportunidad de abastecerse de productos a precios más competitivos. </div>`,
    content: `
    <div class="allFontPage">
        <p>Entendemos que cada cliente tiene unas necesidades únicas, por eso <b>ofrecemos una atención personalizada</b>. En Rakumart valoramos a nuestros clientes y queremos ofrecerles siempre la mejor atención y el mejor servicio. Además, siempre buscamos nuevas formas de ayudar a nuestros clientes a crecer y expandirse. </p>
        <p>Es importante mencionar que en Rakumart <b>NO ofrecemos servicios de venta al por menor o al detalle.</b></p>
    </div>
          `,
    menu: "Empieza en Rakumart", //二级分类名字
    menuLevel: 3,
  },

  // 日志: 开始做Servicios adicionales para productos
  // 文件名
  {
    pageName: "Servicios adicionales para productos",
    router: "servicios-adicionales-para-productos",
    title:
      '<div class="showBodyBoxTitle">Servicios adicionales para productos</div>',
    answer: `<div  class="showBodyBoxAnswer">Rakumart ofrece servicios integrales para satisfacer las necesidades de compra de sus clientes. Contamos con un equipo profesional en nuestras oficinas y almacenes en China que puede realizar tareas específicas según los requisitos de nuestros clientes antes de que los productos sean enviados a España. Algunas de estas tareas incluyen agregar manuales de uso en español, etiquetas de código de barras y fotografías profesionales del producto, entre otras.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      Consulta la siguiente lista para conocer los servicios
      adicionales que dispones para tus productos:
    </p>
    <h3>Fotografía de producto</h3>
    <p>
      Fotografía profesional para venta sin importar el tipo de
      artículo o complejidad.
    </p>
    <p><b>1.04€ (7￥)/u</b></p>
    <h3>Etiquetado Amazon FBA</h3>
    <p>Etiquetado para las bodegas de Amazon FBA</p>
    <p><b>0.15€ (1￥)/u</b></p>
    <h3>Etiquetado con código de barras</h3>
    <p>Incluye cambio de la bolsa de plástico, PP</p>
    <p><b>0.15€ (1￥)/u</b></p>
    <h3>Cambio bolsa de plástico, PP</h3>
    <p>Los artículos voluminosos, se cotizan por separado</p>
    <p><b>0.08€ (0.5￥)/u</b></p>
    <h3>Hoja informativa impresa doble cara</h3>
    <p>
      Por defecto es papel DIN A4, otros tipos de papel o
      impresión de folletos están disponibles bajo petición.
    </p>
    <p><b>0.3€ (2￥)/por hoja</b></p>
    <h3>Cosido de etiqueta de tela</h3>
    <p>
      Cosido de etiquetas personalizadas con tu marca en productos
      textiles como camisetas, camisas, etc.
    </p>
    <p><b>0.15€ (1￥)/u</b></p>
    <h3>Quitar etiquetas de tela</h3>
    <p>
      Quitamos las etiquetas de tela que puedan traer de fábrica
      algunos productos textiles.
    </p>
    <p><b>0.23€ (1.5￥)/u</b></p>
    <h3>Colocar etiquetas colgantes</h3>
    <p>
      Colocación de etiquetas colgantes con tu marca en los
      productos que requieras.
    </p>
    <p><b>0.15€ (1￥)/u</b></p>
    <h3>Quitar etiquetas colgantes</h3>
    <p>
      Quitar etiquetas colgantes de fábrica de los productos que
      requieras.
    </p>
    <p><b>0.15€ (1￥)/u</b></p>
    <h3>Empaquetado conjunto</h3>
    <p>
      Los gastos de manipulación para más de 4 artículos se
      cotizan por separado.
    </p>
    <p><b>0.15€ (1￥)/u</b></p>
    <h3>Proteger con bolsa de burbujas, PP</h3>
    <p>Los artículos voluminosos, se cotizan por separado.</p>
    <p><b>0.15€ (1￥)/u</b></p>
  </div>
          `,
    menu: "Empieza en Rakumart", //二级分类名字
    menuLevel: 3,
  },

  // Servicios de personalización y marca privada
  {
    pageName: "Servicios de personalización y marca privada",
    router: "servicios-de-personalizacion-y-marca-privada",
    title:
      '<div class="showBodyBoxTitle">Servicios de personalización y marca privada</div>',
    answer: `<div  class="showBodyBoxAnswer">Los servicios de personalización y marca privada permiten a las empresas diferenciarse y destacar en el mercado. Al crear productos únicos y personalizados, las empresas pueden aumentar la lealtad del cliente y mejorar su imagen de marca. Además, la marca privada puede ayudar a las empresas a diferenciarse de la competencia y a aumentar su rentabilidad.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      En Rakumart trabajamos para ser el nexo de unión entre las
      necesidades de nuestros clientes y las fábricas en China.
      Asesoramos y acompañamos durante todo el proceso de creación
      y personalización del producto.
    </p>
    <p>
      Servicios de producción de marca privada para tus productos:
    </p>
    <h3><b>Servicio de producción OEM</b></h3>
    <p>
      El cliente elabora un anteproyecto y encargamos a la planta
      de producción que lo produzca, obteniendo así un producto
      completamente original. Dado que el proceso comienza con la
      compra de materiales por parte de la fábrica, el volumen de
      producción inevitablemente aumenta. Contactaremos con el
      fabricante para conocer el volumen de producción, el precio
      unitario, la fecha de entrega y la producción de muestras en
      la planta de producción.
    </p>
    <h3><b>Servicio de producción ODM</b></h3>
    <p>
      Personalización sobre un producto existente agregando las
      funcionalidades o características deseadas por el cliente
      para distribuirlo bajo su marca privada. Este proceso como
      el anterior, comienza con la compra de materiales por parte
      de la fábrica, por lo tanto el volumen de producción
      aumenta. Contactaremos con el fabricante para conocer el
      volumen de producción, el precio unitario, la fecha de
      entrega y la producción de muestras en la planta de
      producción.
    </p>
    <h3><b>Packaging, etiquetas y folletos</b></h3>
    <p>
      Producción de packaging personalizado, etiquetas, paquetes,
      material informativo y elementos distintivos para tu marca
      privada. La producción depende del tamaño, color y del tipo
      de material requerido. Deberás proporcionar todos los
      elementos e información de tu marca para poder realizar un
      presupuesto. Consulta con nuestros agentes los costes de
      montaje y adaptación de los elementos personalizados en el
      conjunto de productos.
    </p>
  </div>
          `,
    menu: "Empieza en Rakumart", //二级分类名字
    menuLevel: 3,
  },

  // ¿Cómo me registro?
  {
    pageName: "¿Cómo me registro?",
    router: "como-me-registro",
    title: '<div class="showBodyBoxTitle">¿Cómo me registro?</div>',
    answer: `<div  class="showBodyBoxAnswer">Registrarse en Rakumart es muy sencillo, solamente necesitas crear una cuenta siguiendo los pasos descritos en la página de registro, haz <a href="/register">clic aquí </a> para crear tu cuenta.
    </div>`,
    content: `
      
          `,
    menu: "Mi cuenta", //二级分类名字
    menuLevel: 3,
  },

  // ¿Por qué no puedo iniciar sesión en mi cuenta?
  {
    pageName: "¿Por qué no puedo iniciar sesión en mi cuenta?",
    router: "por-que-no-puedo-iniciar-sesion-en-mi-cuenta",
    title:
      '<div class="showBodyBoxTitle">¿Por qué no puedo iniciar sesión en mi cuenta?</div>',
    answer: `<div  class="showBodyBoxAnswer">Verifica cuidadosamente tus datos de inicio de sesión. Asegúrate de que la dirección de correo electrónico sea la misma que la registrada y la contraseña correcta. Además, asegúrate de que tu navegador acepte cookies. Puede ser un problema causado por el mantenimiento del sistema Rakumart; espera 15 minutos y vuelve a intentarlo. Si aún no puedes iniciar sesión en tu cuenta, comunícate con nuestro servicio de atención al cliente y comenta el problema.</div>`,
    content: `
      
          `,
    menu: "Mi cuenta", //二级分类名字
    menuLevel: 3,
  },

  // ¿Cómo cambio los detalles de mi cuenta?
  {
    pageName: "¿Cómo cambio los detalles de mi cuenta?",
    router: "como-cambio-los-detalles-de-mi-cuenta",
    title:
      '<div class="showBodyBoxTitle">¿Cómo cambio los detalles de mi cuenta?</div>',
    answer: `<div  class="showBodyBoxAnswer">Después de iniciar sesión, puedes editar los detalles de su cuenta.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      Para proceder debes acceder a tu panel de control y hacer
      clic en el menú lateral izquierdo en el apartado Datos
      personales:
    </p>
    <p>
      <a href="/user/userDetail">Modificar datos personales</a>
    </p>
    <p>
      Haz clic en “Modificar” e introduce la información en el
      campo de texto, a continuación, haz clic en el botón
      “Confirmar” para guardar los cambios.
    </p>
  </div>
          `,
    menu: "Mi cuenta", //二级分类名字
    menuLevel: 3,
  },

  // ¿Cómo editar o restablecer mi contraseña?
  {
    pageName: "¿Cómo editar o restablecer mi contraseña?",
    router: "como-editar-o-restablecer-mi-contrasena",
    title:
      '<div class="showBodyBoxTitle">¿Cómo editar o restablecer mi contraseña?</div>',
    answer: `<div  class="showBodyBoxAnswer">Después de iniciar sesión, puedes editar o reestablecer tu contraseña de acceso.</div>`,
    content: `
    <div class="rightBox thred">
    <div v-html="showpage.title"></div>
    <div v-html="showpage.answer" class="titleFontThree"></div>
    <div class="dianzi"></div>
    <div class="allFontPage">
      <p>
        Para proceder debes acceder a tu panel de control y hacer
        clic en el menú lateral izquierdo en el apartado Datos
        personales:
      </p>
      <ul class="listFont">
        <li>
          <a href="/user/changepassworld?type=password"
            >Contraseña y verificación</a
          >
        </li>
      </ul>
      <p>Sigue los pasos descritos en la pantalla.</p>
    </div>
          `,
    menu: "Mi cuenta", //二级分类名字
    menuLevel: 3,
  },

  // ¿Dónde puedo descargar mis facturas?
  {
    pageName: "¿Dónde puedo descargar mis facturas?",
    router: "donde-puedo-descargar-mis-facturas",
    title:
      '<div class="showBodyBoxTitle">¿Dónde puedo descargar mis facturas?</div>',
    answer: `<div  class="showBodyBoxAnswer">Para descargar tus facturas, por favor sigue los siguientes pasos:</div>`,
    content: `
    <div class="allFontPage">
    <ul class="listFont numStyle">
      <li>
        Accede a tu cuenta en Rakumart.
      </li>
      <li>
        Dirígete a la sección de Facturación en el menú lateral.
      </li>
      <li>
        Haz clic en Historial de facturas.
      </li>
      <li>
        Haz clic en el botón «Descargar» para obtener una copia en
        formato PDF.
      </li>
    </ul>
    <p>
      Recuerda que siempre puedes acceder a tus facturas en
      cualquier momento a través del enlace: <br />
      <a href="/user/invoiceHistory">Historial de facturas</a>
    </p>
  </div>
          `,
    menu: "Mi cuenta", //二级分类名字
    menuLevel: 3,
  },

  // ¿Cómo puedo modificar los datos de facturación?
  {
    pageName: "¿Cómo puedo modificar los datos de facturación?",
    router: "como-puedo-modificar-los-datos-de-facturacion",
    title:
      '<div class="showBodyBoxTitle">¿Cómo puedo modificar los datos de facturación?</div>',
    answer: `<div  class="showBodyBoxAnswer">Para modificar los datos de facturación, por favor sigue los siguientes pasos:</div>`,
    content: `
    <div class="allFontPage">
    <ul class="listFont numStyle">
      <li>
        Accede a tu cuenta en Rakumart.
      </li>
      <li>
        Dirígete a la sección de Facturación en el menú lateral.
      </li>
      <li>
        Haz clic en Historial de facturas.
      </li>
      <li>
        Haz clic en el botón «Editar» de la dirección que deseas
        modificar.
      </li>
    </ul>
    <p>
      Recuerda que también puedes tener varias direcciones de
      facturación. Para crear una nueva dirección sigue los pasos
      anteriores y haz clic en “Añadir una dirección”
    </p>
  </div>
          `,
    menu: "Mi cuenta", //二级分类名字
    menuLevel: 3,
  },
  // ¿Cómo puedo añadir o editar mi dirección de envío?
  {
    pageName: "¿Cómo puedo añadir o editar mi dirección de envío?",
    router: "como-puedo-anadir-o-editar-mi-direccion-de-envio",
    title:
      '<div class="showBodyBoxTitle">¿Cómo puedo añadir o editar mi dirección de envío?</div>',
    answer: `<div  class="showBodyBoxAnswer">Para añadir o editar tu dirección de envío, por favor sigue los siguientes pasos:</div>`,
    content: `
    <div class="allFontPage">
    <ul class="listFont numStyle">
      <li>
        Accede a tu cuenta en Rakumart.
      </li>
      <li>
        Dirígete a la sección de Facturación en el menú lateral.
      </li>
      <li>
        Haz clic en Historial de facturas.
      </li>
      <li>
        Haz clic en el botón «Editar» de la dirección que deseas
        modificar.
      </li>
    </ul>
    <p>
      Recuerda que también puedes tener varias direcciones de
      envío. Para crear una nueva dirección sigue los pasos
      anteriores y haz clic en “Añadir una dirección”
    </p>
  </div>
          `,
    menu: "Mi cuenta", //二级分类名字
    menuLevel: 3,
  },

  // ¿Dónde puedo encontrar los cupones que obtengo?
  {
    pageName: "¿Dónde puedo encontrar los cupones que obtengo?",
    router: "donde-puedo-encontrar-los-cupones-que-obtengo",
    title:
      '<div class="showBodyBoxTitle">¿Dónde puedo encontrar los cupones que obtengo?</div>',
    answer: `<div  class="showBodyBoxAnswer">Para acceder a tus cupones, por favor sigue los siguientes pasos:</div>`,
    content: `
    <div class="allFontPage">
    <ul class="listFont numStyle">
      <li>
        Accede a tu cuenta en Rakumart.
      </li>
      <li>
        En el cuadro «Resumen de tu cuenta», haz clic en Cupones
      </li>
    </ul>
  </div>
          `,
    menu: "Mi cuenta", //二级分类名字
    menuLevel: 3,
  },

  // ¿Cómo usar un cupón?
  {
    pageName: "¿Cómo usar un cupón?",
    router: "como-usar-un-cupon",
    title: '<div class="showBodyBoxTitle">¿Cómo usar un cupón?</div>',
    answer: `<div  class="showBodyBoxAnswer">Si tienes cupones en tu cuenta puedes usarlos en la pantalla de “Detalles del pedido” una vez recibes la cotización para el pago de los productos.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      En la parte inferior de la pantalla, junto al total a pagar,
      encontrarás el botón “Usar un cupón”. Haz clic en el botón y
      una ventana emergente aparecerá con el listado de cupones
      disponibles en tu cuenta. Selecciona el cupón que deseas
      utilizar y este se aplicará automáticamente.
    </p>
    <p>
      Además, si no planeas comprar ningún producto pronto, no te
      preocupes. Puedes guardar tus cupones y usarlos más
      adelante.
    </p>
  </div>
          `,
    menu: "Mi cuenta", //二级分类名字
    menuLevel: 3,
  },
  // ¿Necesito una cuenta para hacer un pedido?
  {
    pageName: "¿Necesito una cuenta para hacer un pedido?",
    router: "necesito-una-cuenta-para-hacer-un-pedido",
    title:
      '<div class="showBodyBoxTitle">¿Necesito una cuenta para hacer un pedido?</div>',
    answer: `<div  class="showBodyBoxAnswer">
    Para poder realizar un pedido en <a href="/">rakumart.es</a>, es necesario que te registres y crees una cuenta. Al utilizar tu propia cuenta tendrás a tu disposición herramientas útiles en tu panel de control para gestionar y supervisar todo el proceso de compra. Además, una vez que hayas creado tu cuenta, tu información personal se almacenará de forma segura en nuestro sistema para que puedas realizar pedidos de manera rápida y sencilla en el futuro.</div>`,
    content: `
          `,
    menu: "Hacer un pedido", //二级分类名字
    menuLevel: 3,
  },
  // ¿Cómo hacer un pedido?
  {
    pageName: "¿Cómo hacer un pedido?",
    router: "como-hacer-un-pedido",
    title: '<div class="showBodyBoxTitle">¿Cómo hacer un pedido?</div>',
    answer: `<div  class="showBodyBoxAnswer">
    Hacer un pedido en <a href="/">rakumart.es</a> es fácil, primero inicia sesión con tu cuenta. Si no tienes cuenta, debes registrarte y crear una (<a href="/register">haz clic aquí para registrarte</a>).
    </div>`,
    content: `
    <div class="conBox allFontPage como-hacer-un-pedido">
    <h2>Guía rápida de usuario:</h2>
    <h3><b>Paso 1</b>: Encuentra y selecciona tus productos</h3>
    <p>
      En Rakumart encontrarás una gran cantidad de opciones a tu
      disposición. Con más de 100.000 fabricantes, seguro que
      podemos ayudarte a encontrar lo que tu negocio necesita.
    </p>
    <div class="hei20"></div>
    <img
      class="cuimg cuimg1"
      src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479aadf413f9.png"
      alt=""
    />
    <div class="hei60"></div>
    <h4>Usa la barra de búsqueda</h4>
    <p>
      ✅ Introduce las palabras clave del nombre del producto o la
      URL en
      <a class="blurLink" href="https://1688.com">1688.com</a> del
      producto que buscas.
    </p>
    <p>
      Nuestro motor de búsqueda traduce automáticamente al idioma
      chino para realizar la búsqueda. Es posible que la
      traducción de las palabras clave que introduzcas no sea
      correcta, por eso recomendamos la búsqueda por imagen.
    </p>
    <p>
      ✅ Haz clic en el icono de la cámara y selecciona la imagen
      del producto que estás buscando.
    </p>
    <div class="hei20"></div>
    <img
      class="cuimg"
      src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479ab8f6113c.gif"
      alt=""
    />
    <div class="hei60"></div>
    <h4>Añade tus productos al carrito</h4>
    <p>
      Una vez en la ficha de producto podrás encontrar los
      detalles del mismo, imágenes, características, precios por
      unidad y especificaciones.
    </p>
    <p>
      ✅ Selecciona las especificaciones tales como color,
      medidas, tipos… y la cantidad de unidades que deseas
      cotizar.
    </p>
    <p>
      ✅ Después haz clic en el botón de
      <span class="cuGreenFont">Añadir al carrito</span>.
    </p>
    <div class="hei65"></div>
    <h4>Paso 2: Empezar un nuevo pedido</h4>
    <p>
      En Rakumart un nuevo pedido se inicia con la cotización por
      parte de nuestro equipo en China de los productos que hayas
      seleccionado.
    </p>
    <div class="hei20"></div>
    <img
      src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479abc66f2a5.gif"
      class="cuimg"
      alt=""
    />
    <div class="hei60"></div>
    <h4>Carrito de compras</h4>
    <p>
      Selecciona los productos que deseas cotizar en un nuevo
      pedido y haz clic en el botón de
      <span class="cuGreenFont">Empezar nuevo pedido</span>.
    </p>
    <div class="hei20"></div>
    <img
      src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479abf4b30f8.gif"
      class="cuimg"
      alt=""
    />
    <div class="hei60"></div>
    <h4>Detalles del pedido</h4>
    <p>
      Selecciona los <b>servicios adicionales</b> que requieras
      para tus productos. Haz clic en el desplegable y elige entre
      el servicio y la cantidad.
    </p>
    <p>
      También puedes escribir otros servicios o
      <b>requisitos especiales</b> que quieras solicitar en la
      cotización, por ejemplo, packaging personalizado,
      personalización de producto con tu marca, etc.
    </p>
    <p>
      Nuestros agentes se pondrán en contacto contigo para
      concretar los detalles.
    </p>
    <div class="hei30"></div>
    <div class="tihsiBox">
      <div class="icon">
        <img
          src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/648013a7cc740.png"
          alt=""
        />
      </div>
      <div class="textCon">
        <div class="contit">Requisitos especiales</div>
        <div class="concon">
          ¿Necesitas personalizar algún elemento de los productos?
          Detalla lo más claro posible los requisitos especiales
          que necesitas en tus productos. Un agente experto de
          nuestro equipo se pondrá en contacto contigo para
          concretar los detalles antes de tramitar la cotización
          con el fabricante.
        </div>
      </div>
    </div>
    <h4>Solicitar cotización</h4>
    <p>
      Una vez completado, haz clic en el botón
      <span class="cuGreenFont">Solicitar cotización</span> para
      que nuestro equipo pueda empezar a tramitar el coste de tus
      productos en China.
    </p>
    <img
      src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479ac9dd3354.jpg"
      class="cuimg"
      alt=""
    />
    <div class="hei60"></div>
    <h3><b>Paso 3</b>: Cotización y pago del pedido</h3>
    <p>
      Tras la confirmación de la solicitud de cotización de tu
      pedido, nuestro equipo de agentes en China se comunica con
      el fabricante para proporcionar la información y los datos
      necesarios para calcular los costes del pedido.
    </p>
    <img
      src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479acdf947a6.gif"
      class="cuimg"
      alt=""
    />
    <div class="hei60"></div>
    <h4>Cotización y detalles del pago</h4>
    <p>
      En un plazo de 24 horas aproximadamente, recibirás un correo
      electrónico con la <strong>cotización del pedido</strong> y
      la cotización de los <strong>gastos de envío</strong> para
      cada método de transporte disponible.
    </p>
    <p>
      Podrás acceder a la cotización en cualquier momento, desde
      el inicio de tu Panel de Control haciendo clic en
      <span style="color: #06ac1c"
        ><strong
          ><span style="text-decoration: underline"
            >Pedidos pendiente de pago</span
          ></strong
        ></span
      >.
    </p>
    <img
      src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479ad15405a2.gif"
      class="cuimg"
      alt=""
    />
    <div class="hei60"></div>
    <h4>Selección del método de transporte</h4>
    <p>
      Antes de proceder al pago deberás elegir el método de
      transporte que más te convenga. Los métodos de transporte
      son los siguientes:
    </p>
    <p>
      <img
        class="molv"
        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6480134708467.svg"
        alt=""
      />
      Transporte marítimo
    </p>
    <p>
      <img
        class="molv"
        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6480134708467.svg"
        alt=""
      />
      Transporte aéreo
    </p>
    <p>
      <img
        class="molv"
        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6480134708467.svg"
        alt=""
      />
      Transporte terrestre
    </p>
    <p>
      <img
        class="molv"
        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6480134708467.svg"
        alt=""
      />
      Transporte ferroviario
    </p>
    <p>
      En cada opción encontrarás el desglose de costes y el tiempo
      de entrega aproximado de cada método de envío.
    </p>
    <div class="tihsiBox marbo30">
      <div class="icon">
        <img
          src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/648013a7cc740.png"
          alt=""
        />
      </div>
      <div class="textCon">
        <div class="contit">Volumen mínimo de transporte</div>
        <div class="concon">
          Los métodos de transporte elegibles dependerán de las
          características de tu pedido (dimensiones, peso exacto
          de los productos, etc.). Si el pedido no supera los
          volúmenes mínimos para algún método de transporte, este
          aparecerá a 0 y no se podrá seleccionar.
        </div>
      </div>
    </div>
    <img
      src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479ad4e70f90.gif"
      class="cuimg"
      alt=""
    />
    <div class="hei60"></div>
    <h4>Pago del pedido</h4>
    <p>
      Una vez seleccionado el método de transporte, se podrá
      proceder al pago del pedidos. Haz clic en el botón
      <span style="color: #06ac1c"
        ><b><u>Proceder al pago</u> </b></span
      >para acceder a la pasarela de pago.
    </p>
    <p>El desglose de costes de los productos incluye:</p>
    <p>
      <img
        class="molv"
        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6480134708467.svg"
        alt=""
      />
      Valor de los productos
    </p>
    <p>
      <img
        class="molv"
        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6480134708467.svg"
        alt=""
      />
      Comisión
    </p>
    <p>
      <img
        class="molv"
        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6480134708467.svg"
        alt=""
      />
      Servicios adicionales (si los hay)
    </p>
    <p>
      <img
        class="molv"
        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6480134708467.svg"
        alt=""
      />
      Logística en China (Control de calidad incluido)
    </p>
    <p>El desglose de costes del envío incluye:</p>
    <p>
      <img
        class="molv"
        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6480134708467.svg"
        alt=""
      />
      Coste transporte internacional
    </p>
    <p>
      <img
        class="molv"
        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6480134708467.svg"
        alt=""
      />
      Aranceles
    </p>
    <p>
      <img
        class="molv"
        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6480134708467.svg"
        alt=""
      />
      IVA
    </p>
    <p>
      <img
        class="molv"
        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6480134708467.svg"
        alt=""
      />
      Envío nacional
    </p>
    <div class="hei20"></div>
    <div class="querenBox">
      <div class="oicon">
        <img
          src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6480142c9ace6.svg"
          alt=""
        />
      </div>
      <div class="otit">Pedido realizado correctamente</div>
      <div class="ocon">
        Cuando el pago ha sido realizado correctamente, nuestro
        equipo hace el pedido a fábrica a la espera de recibirlo
        en nuestros almacenes en China.
      </div>
    </div>
    <h3><b>Paso 4</b>: Logística en China</h3>
    <p>
      La mercancía se pondrá en marcha desde el fabricante hasta
      nuestros almacenes en China. Una vez recepcionada, nuestro
      equipo realiza un control de calidad estándar, los servicios
      adicionales que requiera y la prepará para el envío
      internacional.
    </p>
    <img
      src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479ad9ec8cfa.gif"
      class="cuimg"
      alt=""
    />
    <div class="hei60"></div>
    <h4>Control de calidad</h4>
    <p>
      El control de calidad estándar que realizamos cuando
      recibimos la mercancía desde fábrica incluye las siguientes
      comprobaciones:
    </p>
    <p>
      <img
        class="molv"
        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6480134708467.svg"
        alt=""
      /><b>Comprobación de la entrada del pedido</b>: verificamos
      que el producto recibido coincide con el producto que hemos
      pedido.
    </p>
    <p>
      <img
        class="molv"
        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6480134708467.svg"
        alt=""
      /><b>Comprobación de daños visibles</b>: revisamos
      visualmente el producto para detectar cualquier daño o
      defecto evidente.
    </p>
    <p>
      <img
        class="molv"
        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6480134708467.svg"
        alt=""
      /><b>Verificación de cualquier diferencia</b> importante
      entre la página del producto y el producto recibido:
      comprobamos que el producto recibido coincide con la
      descripción y las especificaciones que aparecen en la página
      del producto.
    </p>
    <img
      src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479ae05edd02.gif"
      class="cuimg"
      alt=""
    />
    <div class="hei60"></div>
    <h4>Manipulación y servicios adicionales</h4>
    <p>
      En el caso de que el pedido contemple servicios adicionales,
      nuestro equipo procederá a la realización de los mismos una
      vez verificado el control de calidad. Después, la mercancía
      se prepara para la recogida del envío internacional.
    </p>
    <img
      src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479ae4ee1ced.gif"
      class="cuimg"
      alt=""
    />
    <div class="hei60"></div>
    <div class="tihsiBox">
      <div class="icon">
        <img
          src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/648013a7cc740.png"
          alt=""
        />
      </div>
      <div class="textCon">
        <div class="contit">
          Almacenamiento en China disponible
        </div>
        <div class="concon">
          Rakumart ofrece almacenamiento en China gratuito a
          nuestros clientes. De esta manera es posible almacenar
          la mercancía e ir importando según las necesidades de
          cada cliente y su negocio.
        </div>
      </div>
    </div>
    <h3>
      <b>Paso 5</b>: Envío, importación y entrega del pedido
    </h3>
    <p>
      Empieza la importación. La mercancía pone rumbo a España
      mediante el método de transporte seleccionado.
    </p>
    <img
      src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/6479ae875d706.gif"
      class="cuimg"
      alt=""
    />
    <div class="hei60"></div>
    <h4>Envío internacional</h4>
    <p>
      Cuando la logística en China a terminado, nuestro equipo
      gestiona todos los trámites pertinentes para el transporte
      internacional a la espera de la recogida de la mercancía
      desde nuestros almacenes.
    </p>
    <p>
      En este proceso se generará un código de envío internacional
      que pondremos a tu disposición para el seguimiento
      correspondiente de la mercancía.
    </p>
    <div class="hei60"></div>
    <h4>Despacho de aduanas</h4>
    <p>
      Al llegar a España, las mercancías deben pasar por trámites
      aduaneros. Todos los trámites correspondientes y
      procedimientos de pago de impuestos serán tramitados por
      Rakumart. En este punto Rakumart se encarga por ti de todos
      los tramites y burocracias.
    </p>
    <p>
      Nuestros clientes también podrán elegir la opción de
      realizar el despacho de aduanas por ellos mismos o mediante
      un tercero.
    </p>
    <div class="hei60"></div>
    <h4>Envío nacional y entrega</h4>
    <p>
      Con la mercancía ya en nuestros
      <b>almacenes de Barcelona</b>, nuestro partner logístico
      nacional se encargará de transportar el pedido hasta la
      dirección de envío elegida <b>para la entrega</b>.
    </p>
    <p>
      Después de comprobar la mercancía y haber completado tu
      pedido, nos gustaría saber tu opinión.
    </p>
    <p>
      Puedes contactar con tu agente asignado para resolver
      cualquier duda que pudieras tener sobre tu pedido.
    </p>
    <p>¡Estamos a tu disposición!</p>
  </div>
          `,
    menu: "Hacer un pedido", //二级分类名字
    menuLevel: 3,
  },

  // ¿Puedo comprar muestras?
  {
    pageName: "¿Puedo comprar muestras?",
    router: "puedo-comprar-muestras",
    title: '<div class="showBodyBoxTitle">¿Puedo comprar muestras?</div>',
    answer: `<div  class="showBodyBoxAnswer">Sí, Rakumart ofrece a sus clientes la posibilidad de adquirir muestras de los productos que deseen importar. La compra de estas muestras les permite verificar las prestaciones de los productos antes de realizar una compra mayor. Además, las muestras pueden ser de gran ayuda para la toma de decisiones de compra, ya que permiten evaluar la calidad del producto, su resistencia, durabilidad y capacidad de respuesta a diferentes situaciones.</div>`,
    content: `
    <div class="allFontPage">
                  <p>
                    Es importante tener en cuenta que la cantidad mínima de muestras que se pueden adquirir, está sujeta a la disponibilidad del fabricante.
                  </p>
                </div>
        `,
    menu: "Hacer un pedido", //二级分类名字
    menuLevel: 3,
  },
  // ¿Cuándo recibo la cotización?
  {
    pageName: "¿Cuándo recibo la cotización?",
    router: "cuando-recibo-la-cotizacion",
    title: '<div class="showBodyBoxTitle">¿Cuándo recibo la cotización?</div>',
    answer: `<div  class="showBodyBoxAnswer">En un plazo de <b>24 horas</b> aproximadamente, recibirás un correo electrónico con la cotización del pedido y la cotización de los gastos de envío para cada método de transporte disponible.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      Tras la confirmación de la solicitud de cotización de tu
      pedido, nuestro equipo de agentes en China se pone en
      contacto con el fabricante aportando la información y datos
      de tu pedido para realizar el cálculo de costes.
    </p>    
    <p>
      Con Rakumart es posible agrupar la mercancía de distintos
      proveedores de tal manera que los trámites y costes de envío
      se reunifican. Esto ayuda a nuestros clientes a la hora de
      optimizar la importación y ser más competitivos.
    </p>
  </div>
          `,
    menu: "Hacer un pedido", //二级分类名字
    menuLevel: 3,
  },

  // ¿Cómo consultar el estado de mi pedido?
  {
    pageName: "¿Cómo consultar el estado de mi pedido?",
    router: "como-consultar-el-estado-de-mi-pedido",
    title:
      '<div class="showBodyBoxTitle">¿Cómo consultar el estado de mi pedido?</div>',
    answer: `<div  class="showBodyBoxAnswer">
    Para mantener un control total sobre tus pedidos, Rakumart te permite acceder al estado de los mismos directamente desde tu panel de control. Para ello, simplemente debes acceder a tu cuenta en <a href="/">rakumart.es</a> y buscar los accesos directos del estado de tus pedidos en la pantalla de inicio de tu panel. También puedes encontrar más detalles relacionados con tus pedidos en la pestaña «Gestión de pedidos» que se encuentra en el menú lateral de tu panel.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      De esta manera, podrás tener un control completo sobre la
      situación de tus pedidos y hacer un seguimiento detallado de
      cada uno de ellos. Además, podrás aprovechar la información
      detallada disponible para tomar decisiones informadas sobre
      tus compras futuras en Rakumart, y así obtener la mejor
      experiencia de compra posible.
    </p>
    <h3>Accesos directos al estado de tus pedidos:</h3>
    <ul class="listFont">
      <li>
        <b>Pedidos guardados temporalmente: </b> Estos son los
        pedidos que has guardado en tu cuenta de Rakumart, pero
        que aún no has confirmado ni pagado.
      </li>
      <li>
        <b>Todos los pedidos en curso: </b> Esta es la cantidad
        total de pedidos que tienes actualmente en curso, es
        decir, que han sido confirmados y se encuentran en
        proceso.
      </li>
      <li>
        <b>Recepción pendiente desde fábrica: </b>Este estado se
        refiere a que el producto que has comprado aún no ha sido
        recibido por Rakumart desde el proveedor.
      </li>
      <li>
        <b>Control de calidad y manipulación: </b>En este estado,
        el producto se encuentra en proceso de control de calidad
        y manipulación por parte de Rakumart antes de ser enviado
        a tu dirección.
      </li>
      <li>
        <b>Mercancia preparada en almacén: </b>Los productos que
        has comprado se encuentran en el almacén de Rakumart y
        están preparados para su envío.
      </li>
      <li>
        Flete pendiente de cotización: Este estado indica que el
        costo del envío aún no ha sido calculado y cotizado.
      </li>
      <li>
        <b>Envío internacional pendiente de pago: </b>El producto
        se encuentra preparado para el envío internacional y aún
        no se ha completado el pago correspondiente.
      </li>
      <li>
        <b>En espera de recogida para envío: </b>El producto está
        listo para ser enviado, pero aún no ha sido recogido por
        la compañía de transporte.
      </li>
      <li>
        <b>En transito hacia España: </b>El producto se encuentra
        en proceso de envío hacia España.
      </li>
      <li>
        <b>En almacén de Rakumart España: </b>El producto ha
        llegado al almacén de Rakumart en España y está siendo
        preparado para su entrega.
      </li>
      <li>
        <b>Envío nacional en curso: </b>El producto se encuentra
        en proceso de envío a la dirección que proporcionaste.
      </li>
      <li>
        <b>Recibidos: </b>El producto ha sido entregado y recibido
        satisfactoriamente en la dirección que proporcionaste.
      </li>
    </ul>
  </div>
          `,
    menu: "Hacer un pedido", //二级分类名字
    menuLevel: 3,
  },

  // ¿Qué significa “Pedido pendiente de cotización”?
  {
    pageName: "¿Qué significa “Pedido pendiente de cotización”?",
    router: "que-significa-pedido-pendiente-de-cotizacion",
    title:
      '<div class="showBodyBoxTitle">¿Qué significa “Pedido pendiente de cotización”?</div>',
    answer: `<div  class="showBodyBoxAnswer">
    “Pedido pendiente de cotización” es un estado que indica que <b>tu solicitud de cotización para tus productos está en proceso.</b> Esto significa que nuestro equipo de agentes en China está actualmente trabajando en tu pedido. Después de que se confirme tu solicitud de cotización, nuestro equipo se pondrá en contacto con el fabricante y proporcionará toda la información y datos necesarios para calcular los costes.
    </div>`,
    content: `
    <div class="allFontPage">
    <p>
      Es importante destacar que nuestro equipo de agentes en
      China trabaja directamente con los fabricantes para
      garantizar que los costes sean lo más precisos posible. Esto
      se hace para asegurarnos de que puedas disfrutar de los
      mejores precios y servicios de envío disponibles.
    </p>
    <p>
      Una vez que se complete el cálculo de costes, recibirás un
      correo electrónico con la cotización estimada de los gastos
      de envío para cada método de transporte disponible. También
      recibirás un enlace al coste final de los productos en
      China. Esto te permitirá tener una idea clara de cuánto
      costará el envío y los productos que deseas comprar.
    </p>
    <p>
      Por lo general, el proceso de cotización toma alrededor de
      <b>24 horas</b>. Esto se debe a que queremos asegurarnos de
      que se hagan los cálculos precisos para ofrecerte el mejor
      servicio posible. Sin embargo, si tienes alguna pregunta o
      preocupación, no dudes en contactarnos en cualquier momento.
      Estamos aquí para ayudarte en todo lo que necesites.
    </p>
  </div>
          `,
    menu: "Hacer un pedido", //二级分类名字
    menuLevel: 3,
  },
  // ¿Qué significa “Pedido de productos pendiente de pago”?
  {
    pageName: "¿Qué significa “Pedido de productos pendiente de pago”?",
    router: "que-significa-pedido-de-productos-pendiente-de-pago",
    title:
      '<div class="showBodyBoxTitle">¿Qué significa “Pedido de productos pendiente de pago”?</div>',
    answer: `<div  class="showBodyBoxAnswer">«Pedido de productos pendiente de pago” es un estado que quiere decir que ya has recibido la cotización de los productos y el coste estimado de envío por parte de nuestros agentes en China, por lo tanto, <b>ya puedes realizar el primero pago </b>(productos) de tu pedido.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      El segundo pago (envío) se realizará cuando la mercancía de
      tu pedido esté preparada para ser enviada en nuestros
      almacenes en China, donde se llevará a cabo el control de
      calidad, servicios adicionales, si así lo requiere, y la
      cotización final del envío, la cual dispondrás antes de
      realizar este segundo pago.
    </p>
  </div>
          `,
    menu: "Hacer un pedido", //二级分类名字
    menuLevel: 3,
  },
  // ¿Qué significa “Envío internacional pendiente de pago”?
  {
    pageName: "¿Qué significa “Envío internacional pendiente de pago”?",
    router: "que-significa-envio-internacional-pendiente-de-pago",
    title:
      '<div class="showBodyBoxTitle">¿Qué significa “Envío internacional pendiente de pago”?</div>',
    answer: `<div  class="showBodyBoxAnswer">“Envío internacional pendiente de pago” es un estado que indica que tu pedido está preparado para ser enviado hacia España, pero aún no se ha pagado la tarifa correspondiente.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      Para acceder a la pantalla de pago inicia sesión en tu
      cuenta de <a href="/">rakumart.es</a> y sigue cualquiera de estos pasos:
    </p>
    <ul class="listFont numStyle">
      <li>
        Haz clic en el aviso de color azul que encontrarás en el
        cuadro de “Alertas de tus pedidos” situado en la pantalla
        de inicio de tu panel de control.
      </li>
      <li>
        Haz clic en la pestaña central “Envío internacional
        pendiente de pago” que se encuentra dentro del menú
        lateral “Gestión de pedidos”.
      </li>
      <li>
        También puedes acceder directamente desde el correo
        electrónico que habrás recibido con la información
        correspondiente y el asunto: “Ya puedes pagar el envío de
        tu mercancía”
      </li>
    </ul>
  </div>
          `,
    menu: "Hacer un pedido", //二级分类名字
    menuLevel: 3,
  },

  // '¿Hay cantidad mínima de compra?
  {
    pageName: "¿Hay cantidad mínima de compra?",
    router: "hay-cantidad-minima-de-compra",
    title:
      '<div class="showBodyBoxTitle">¿Hay cantidad mínima de compra?</div>',
    answer: `<div  class="showBodyBoxAnswer">Rakumart es una plataforma de importación y no ofrecemos venta al detalle.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      La cantidad mínima de compra la determina el fabricante.
    </p>
    <p>
      Debes tener en cuenta que los precios que se muestran las
      fichas de producto solo incluyen el costo del mismo, y no
      incluyen otros gastos como envío, impuestos o aranceles. Por
      lo tanto, comprar pocas unidades no sería rentable si se
      importa desde China, a menos que se esté comprando muestras
      para evaluar la calidad antes de realizar una importación
      futura.
    </p>
    <p>
      <a href="/puedo-comprar-muestras"
        >Leer más sobre la compra de muestras.</a
      >
    </p>
  </div>
            `,
    menu: "Hacer un pedido", //二级分类名字
    menuLevel: 3,
  },

  // ¿Puedo hacer cambios en el pedido que hice?
  {
    pageName: "¿Puedo hacer cambios en el pedido que hice?",
    router: "puedo-hacer-cambios-en-el-pedido-que-hice",
    title:
      '<div class="showBodyBoxTitle">¿Puedo hacer cambios en el pedido que hice?</div>',
    answer: `<div  class="showBodyBoxAnswer">Es posible modificar los pedidos que todavía están pendiente pago, ya que una vez el pago de los productos ha sido realizado, nuestros agentes gestionan la compra de la mercancía con la fábrica.</div>`,
    content: `
      内容
          `,
    menu: "Hacer un pedido", //二级分类名字
    menuLevel: 3,
  },

  // Por qué no recibí un correo electrónico de confirmación sobre mi pedido
  {
    pageName:
      "Por qué no recibí un correo electrónico de confirmación sobre mi pedido",
    router:
      "por-que-no-recibi-un-correo-electronico-de-confirmacion-sobre-mi-pedido",
    title:
      '<div class="showBodyBoxTitle">Por qué no recibí un correo electrónico de confirmación sobre mi pedido</div>',
    answer: `<div  class="showBodyBoxAnswer">Al realizar el primer pago para tu pedido de productos en Rakumart, recibirás un correo electrónico de confirmación de manera automática. Este correo contiene información importante relacionada con tu pedido, por lo que te recomendamos revisarlo detenidamente.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      Te recordamos que, en caso de no encontrar el correo de
      confirmación en tu bandeja de entrada, debes revisar tu
      carpeta de spam. Si encuentras el correo aquí, por favor
      marca los correos de Rakumart como correo de confianza para
      que no se filtren en la carpeta de spam en el futuro.
    </p>
    <p>
      Si después de revisar ambas carpetas aún no encuentras el
      correo de confirmación, por favor no dudes en contactar a
      nuestro equipo de soporte. Estaremos encantados de ayudarte
      a resolver cualquier problema que tengas con tu pedido.
    </p>
  </div>
          `,
    menu: "Hacer un pedido", //二级分类名字
    menuLevel: 3,
  },

  // ¿Cuál es el significado de los diferentes estados de pedido?
  {
    pageName: "¿Cuál es el significado de los diferentes estados de pedido?",
    router: "cual-es-el-significado-de-los-diferentes-estados-de-pedido",
    title:
      '<div class="showBodyBoxTitle">¿Cuál es el significado de los diferentes estados de pedido?</div>',
    answer: `<div  class="showBodyBoxAnswer"></div>`,
    content: `
    <div class="allFontPage">
                  <ul class="listFont">
                    <li>
                      <b>Recepción pendiente desde fábrica:</b> se refiere a
                      aquellos productos que se han solicitado a los proveedores
                      y aún no han llegado al almacén de Rakumart.
                    </li>
                    <li>
                      <b>Control de calidad y manipulación:</b> se refiere al
                      proceso de revisión y manipulación de los productos que se
                      reciben en el almacén de Rakumart. Se verifican las
                      condiciones del producto recibido y se manipulan
                      adecuadamente para su almacenamiento.
                    </li>
                    <li>
                      <b>Mercancia preparada en almacén:</b> se refiere a
                      aquellos productos que ya han pasado el control de calidad
                      y manipulación y están listos para ser enviados.
                    </li>
                    <li>
                      <b>Flete pendiente de cotización:</b> se refiere a la
                      espera de una cotización para el costo del envío, que
                      depende de las dimensiones de la mercancía.
                    </li>
                    <li>
                      <b>Envío internacional pendiente de pago:</b> se refiere a
                      aquellos pedidos que se han procesado y están listos para
                      ser enviados al extranjero, pero aún no se ha realizado el
                      pago correspondiente.
                    </li>
                    <li>
                      <b>En espera de recogida para envío:</b> se refiere a
                      aquellos productos que ya están preparados para ser
                      enviados, pero se encuentran en espera de ser recogidos
                      por la empresa de transporte.
                    </li>
                    <li>
                      <b>En transito hacia España:</b> se refiere a los
                      productos que ya han sido enviados y se encuentran en
                      tránsito hacia el almacén de Rakumart en España.
                    </li>
                    <li>
                      <b>En almacén de Rakumart España:</b> se refiere a
                      aquellos productos que ya han llegado al almacén de
                      Rakumart en España y están listos para ser enviados a los
                      clientes.
                    </li>
                    <li>
                      <b>Envío nacional en curso:</b> se refiere a aquellos
                      pedidos que ya han sido enviados dentro del territorio
                      español y están en proceso de entrega.
                    </li>
                    <li>
                      <b>Recibidos:</b> se refiere a aquellos pedidos que ya han
                      sido entregados a los clientes.
                    </li>
                  </ul>
                </div>
        `,
    menu: "Hacer un pedido", //二级分类名字
    menuLevel: 3,
  },
  // ¿Cómo agregar artículos a un pedido confirmado?
  {
    pageName: "¿Cómo agregar artículos a un pedido confirmado?",
    router: "como-agregar-articulos-a-un-pedido-confirmado",
    title:
      '<div class="showBodyBoxTitle">¿Cómo agregar artículos a un pedido confirmado?</div>',
    answer: `<div  class="showBodyBoxAnswer">Si ya confirmaste tu pedido pero te das cuenta que necesitas agregar algunos artículos más, no te preocupes, ¡todavía hay solución! Puedes comunicarte con nuestro equipo de soporte para solicitar su ayuda. Ellos se encargarán de confirmar si aún es posible agregar artículos a tu pedido. Si la respuesta es sí, no te preocupes, ellos mismos te ayudarán a hacerlo.</div>`,
    content: `
      
          `,
    menu: "Hacer un pedido", //二级分类名字
    menuLevel: 3,
  },
  // ¿Cómo cancelar el pedido?
  {
    pageName: "¿Cómo cancelar el pedido?",
    router: "como-cancelar-el-pedido",
    title: '<div class="showBodyBoxTitle">¿Cómo cancelar el pedido?</div>',
    answer: `<div  class="showBodyBoxAnswer">Si deseas cancelar un pedido ponte en contacto con nuestro equipo de soporte.</div>`,
    content: `
  <div class="allFontPage">
    <p>Debes tener en cuenta que al realizar importaciones específicas a petición del cliente desde un tercer país fuera del espacio económico europeo, solo se podrá llevar a cabo la cancelación de la compra si aún no se ha iniciado el transporte de la mercancía, no siendo relevante la cantidad de la misma, o la mercancía está defectuosa.</p>
    <p>Puedes comunicarte con nuestro equipo de soporte para solicitar su ayuda.</p>
  </div>
        `,
    menu: "Hacer un pedido", //二级分类名字
    menuLevel: 3,
  },

  // Proceso de pagos
  {
    pageName: "Proceso de pagos",
    router: "proceso-de-pagos",
    title: '<div class="showBodyBoxTitle">Proceso de pagos</div>',
    answer: `<div  class="showBodyBoxAnswer">En el proceso de pago de tus pedidos, encontrarás dos maneras de proceder:</div>`,
    content: `
    <div class="allFontPage">
    <ul class="listFont numStyle">
      <li>
      <b>Pago de los productos y el envío al mismo tiempo</b>: Selecciona esta opción para realizar el pago de los productos y el envío al mismo tiempo. Esta es la opción por defecto, todo el proceso queda resuelto en un solo pago y es la manera más rápida de proceder con la importación de tu pedido.
      </li>
      <li>
      <b>Pago por separado</b>: Elige esta opción para realizar el pago de los productos y el envío por separado. Primero realiza el pago de los productos y una vez la mercancía esté dispuesta en nuestros almacenes en China, podrás realizar el pago del envío. Esta opción es interesante para agrupar mercancías de distintos pedidos o hacer uso de nuestros almacenes en China.
      </li>
    </ul>
  </div>
          `,
    menu: "Pagos", //二级分类名字
    menuLevel: 3,
  },
  // ¿Cómo puedo realizar el pago de un pedido?
  {
    pageName: "¿Cómo puedo realizar el pago de un pedido?",
    router: "como-puedo-realizar-el-pago-de-los-productos",
    title:
      '<div class="showBodyBoxTitle">¿Cómo puedo realizar el pago de un pedido?</div>',
    answer: `<div  class="showBodyBoxAnswer">Sigue los siguiente pasos para realizar el pago de tu pedido:</div>`,
    content: `
    <div class="allFontPage">
    <ul class="listFont numStyle">
      <li>Inicia sesión en tu cuenta de <a href="/">rakumart.es</a></li>
      <li>Accede a tu panel de control</li>
      <li>
        Haz clic en el apartado “Gestión de pedidos” situado en el
        menú lateral izquierdo del panel
      </li>
      <li>Haz clic en el apartado “Pedidos en curso”</li>
      <li>Haz clic en la pestaña “Pendiente de pago”</li>
      <li>
        En el listado de pedidos pendiente de pago, haz clic en el
        botón azul “Pagar ahora”
      </li>
      <li>
        En la pantalla de detalles del pedido haz clic en el botón
        verde: “Proceder al pago”
      </li>
      <li>
        En la pasarela de pago, rellena los datos de facturación y
        dirección de entrega
      </li>
      <li>
        Elige un método de pago, transferencia bancaria o tarjeta
        de crédito
      </li>
      <li>
        Sigue las instrucciones de pago por transferencia o haz
        clic en “Realizar el pago” por tarjeta de crédito
      </li>
    </ul>
    <p>
      El pago de los productos se realiza siempre a través de
      nuestra pasarela de pagos vinculada a Banco Santander.
    </p>
  </div>
          `,
    menu: "Pagos", //二级分类名字
    menuLevel: 3,
  },

  // ¿Cómo puedo realizar el pago del envío?
  {
    pageName: "¿Cómo puedo realizar el pago del envío?",
    router: "como-puedo-realizar-el-pago-del-envio",
    title:
      '<div class="showBodyBoxTitle">¿Cómo puedo realizar el pago del envío?</div>',
    answer: `<div  class="showBodyBoxAnswer">Sigue los siguiente pasos para realizar el pago del envío de tu pedido:</div>`,
    content: `
    <div class="allFontPage">
    <ul class="listFont numStyle">
      <li>
        Inicia sesión en tu cuenta de <a href="/">rakumart.es</a>
      </li>
      <li>Accede a tu panel de control</li>
      <li>
        Haz clic en el apartado “Gestión de pedidos” situado en el
        menú lateral izquierdo del panel
      </li>
      <li>Haz clic en el apartado “Almacén Rakumart”</li>
      <li>
        Haz clic en la pestaña “Envío internacional pendiente de
        pago”
      </li>
      <li>
        En el listado de pedidos pendiente de pago, haz clic en el
        botón azul “Pagar ahora”
      </li>
      <li>
        En la pantalla de detalles del pedido haz clic en el botón
        verde: “Proceder al pago”
      </li>
      <li>
        Los datos de facturación y dirección de entrega ya han
        sido rellenados anteriormente en el pago de los productos.
        Revisa y confirma que sean correctos.
      </li>
      <li>
        Elige un método de pago, transferencia bancaria o tarjeta
        de crédito
      </li>
      <li>
        Sigue las instrucciones de pago por transferencia o haz
        clic en “Realizar el pago” por tarjeta de crédito
      </li>
    </ul>
    <p>
      El pago de los productos se realiza siempre a través de
      nuestra pasarela de pagos vinculada a Banco Santander.
    </p>
  </div>
          `,
    menu: "Pagos", //二级分类名字
    menuLevel: 3,
  },

  // ¿Cuáles son las tarifas de la compra de productos?
  {
    pageName: "¿Cuáles son las tarifas de la compra de productos?",
    router: "cuales-son-las-tarifas-de-la-compra-de-productos",
    title:
      '<div class="showBodyBoxTitle">¿Cuáles son las tarifas de la compra de productos?</div>',
    answer: `<div  class="showBodyBoxAnswer">Los precios mostrado en el sitio web <a href="/">rakumart.es</a> corresponden al valor del producto en fábrica. A estos precios hay que sumarle varias tarifas:</div>`,
    content: `
    <div class="allFontPage">
    <ul class="listFont">
      <li>
        Comisión Rakumart (<a href="/comisiones-rakumart"
          >ver comisiones</a
        >)
      </li>
      <li>Coste transporte interno de China</li>
      <li>
        Servicios adicionales (si los ha requerido el cliente)
      </li>
      <li>Envío internacional</li>
      <li>Transporte en España</li>
      <li>Aranceles</li>
      <li>IVA</li>
    </ul>
    <p>
      Para conocer los detalles de un producto en concreto,
      realiza la solicitud de cotización, en 24 horas nuestro
      equipo te hará llegar la información con los costes
      asociados a tu pedido.
    </p>
  </div>
          `,
    menu: "Pagos", //二级分类名字
    menuLevel: 3,
  },

  // ¿Debo pagar otras tarifas cuando llegue el pedido?
  {
    pageName: "¿Debo pagar otras tarifas cuando llegue el pedido?",
    router: "debo-pagar-otras-tarifas-cuando-llegue-el-pedido",
    title:
      '<div class="showBodyBoxTitle">¿Debo pagar otras tarifas cuando llegue el pedido?</div>',
    answer: `<div  class="showBodyBoxAnswer">No, todas las tarifas han quedado resueltas en el proceso de compra. Esto significa que, una vez que hayas completado el proceso de compra, no deberías tener que preocuparte por ninguna tarifa adicional. Sin embargo, si por algún motivo extraordinario tu mercancía estuviera sujeta a otras tarifas, ponte en contacto con nuestro equipo de soporte.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      Nuestros agentes estarán encantados de ayudarte en cualquier
      problema que puedas tener. Además, puedes estar seguro de
      que trabajamos arduamente para garantizar que nuestros
      clientes experimenten una experiencia de compra sin
      problemas.
    </p>
    <p>
      Desde la selección de productos hasta la entrega final,
      nuestros clientes son nuestra máxima prioridad. Es por eso
      que ofrecemos un equipo de soporte dedicado, disponible en
      todo momento para ayudar con cualquier problema que pueda
      surgir.
    </p>
  </div>
          `,
    menu: "Pagos", //二级分类名字
    menuLevel: 3,
  },

  // ¿Debo pagar el control de calidad?
  {
    pageName: "¿Debo pagar el control de calidad?",
    router: "debo-pagar-el-control-de-calidad",
    title:
      '<div class="showBodyBoxTitle">¿Debo pagar el control de calidad?</div>',
    answer: `<div  class="showBodyBoxAnswer">No, el control de calidad que realiza el equipo de Rakumart en nuestros almacenes en China queda incluido en las tarifas de coste del producto. Esto significa que nuestros clientes pueden estar seguros de que todos los productos que les enviamos han pasado por un riguroso proceso de control de calidad antes de ser empacados y enviados.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      Sin embargo, entendemos que algunos de nuestros clientes
      pueden desear un control de calidad adicional o
      personalizado para asegurarse de que los productos cumplen
      con sus requisitos específicos. Si desea realizar otro tipo
      de control de calidad, puede ponerse en contacto con nuestro
      equipo de soporte para conocer el coste del mismo.
    </p>
    <p>
      Nuestro equipo de soporte estará encantado de ayudarle a
      encontrar la mejor solución de control de calidad para sus
      necesidades específicas, y garantizar que reciba los
      productos de la más alta calidad.
    </p>
  </div>
          `,
    menu: "Pagos", //二级分类名字
    menuLevel: 3,
  },

  // ¿Qué tipo de pagos se aceptan?
  {
    pageName: "¿Qué tipo de pagos se aceptan?",
    router: "que-tipo-de-pagos-se-aceptan",
    title: '<div class="showBodyBoxTitle">¿Qué tipo de pagos se aceptan?</div>',
    answer: `<div  class="showBodyBoxAnswer">Si deseas realizar una compra en nuestro sitio web, tienes dos opciones de pago disponibles:</div>`,
    content: `
    <div class="allFontPage">
    <p>
      Puedes pagar con tarjeta de crédito o bien, realizar una
      transferencia bancaria a nuestro número de cuenta del Banco
      Santander.
    </p>
    <p>
      Si eliges pagar con tarjeta de crédito, puedes estar seguro
      de que tu información personal estará protegida por nuestro
      avanzado sistema de seguridad. Por otro lado, si prefieres
      hacer una transferencia bancaria, asegúrate de ingresar
      correctamente los datos de nuestra cuenta para evitar
      cualquier retraso en el procesamiento de tu pedido y seguir
      todas las instrucciones descritas.
    </p>
    <p>
      Ten en cuenta que los tiempos de procesamiento pueden variar
      según el método de pago elegido.
    </p>
  </div>
          `,
    menu: "Pagos", //二级分类名字
    menuLevel: 3,
  },

  // ¿Qué divisas puedo usar?
  {
    pageName: "¿Qué divisas puedo usar?",
    router: "que-divisas-puedo-usar",
    title: '<div class="showBodyBoxTitle">¿Qué divisas puedo usar?</div>',
    answer: `<div  class="showBodyBoxAnswer">En <a href="http://rakumart.es/">rakumart.es</a> solo aceptamos pago en Euros. Nuestros clientes pueden pagar con tarjeta de crédito o transferencia bancaria. </div>`,
    content: `
    <div class="allFontPage">
    <p>
    En caso de duda o problema, nuestro equipo de atención al cliente está disponible para ayudar y resolver cualquier problema de forma rápida y eficiente.
    </p>
   
  </div>
          `,
    menu: "Pagos", //二级分类名字
    menuLevel: 3,
  },

  // ¿Cuál es el coste del envío?
  {
    pageName: "¿Cuál es el coste del envío?",
    router: "cual-es-el-coste-del-envio",
    title: '<div class="showBodyBoxTitle">¿Cuál es el coste del envío?</div>',
    answer: `<div  class="showBodyBoxAnswer">El coste del envío dependerá del volumen de cada pedido y del método de transporte seleccionado. Por lo general el método de transporte marítimo es el más económico, seguido del transporte terrestre y el transporte aéreo. En pedidos con un peso superior o igual a 21 kg, los gastos de envío serán más económicos.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      El coste del envío se desglosa en los siguientes puntos:
    </p>
    <ul class="listFont">
      <li>Transporte internacional (China a España)</li>
      <li>Logística en España</li>
      <li>Aranceles</li>
      <li>IVA</li>
    </ul>
    <p>
      Para conocer los detalles del coste de un producto en
      concreto, realiza la solicitud de cotización, en 24 horas
      nuestro equipo te hará llegar la información con los costes
      asociados a tu pedido.
    </p>
    <p>
      Recuerda que el coste de envío no está incluido en el precio
      del producto y que variará según el volumen y método de
      transporte que elijas. Si tienes alguna duda sobre los
      costes asociados a tu pedido, no dudes en ponerte en
      contacto con nuestro equipo de atención al cliente para
      obtener una cotización detallada.
    </p>
  </div>
          `,
    menu: "Logística y envíos", //二级分类名字
    menuLevel: 3,
  },

  // ¿Cuánto tardaré en recibir mi pedido?
  {
    pageName: "¿Cuánto tardaré en recibir mi pedido?",
    router: "cuanto-tardare-en-recibir-mi-pedido",
    title:
      '<div class="showBodyBoxTitle">¿Cuánto tardaré en recibir mi pedido?</div>',
    answer: `<div  class="showBodyBoxAnswer">
    En el tiempo de entrega, desde que sea realiza el pedido hasta que el cliente lo recibe, hay que tener 4 factores en cuenta:
    </div>`,
    content: `
    <div class="allFontPage">
  <ul class="listFont numStyle">
    <li>
      <b>Tiempo de transporte en China</b>, desde fábrica hasta nuestros
      almacenes. Dependerá de la distancia entre la fábrica y nuestros almacenes
      y del tiempo de manipulación de la mercancía por parte del fabricante. El
      tiempo estimado puede ser de 2 a 4 días.
    </li>
    <li>
      El tiempo del proceso del
      <b>control de calidad y preparación del envío internacional</b> en
      nuestros almacenes. Este proceso puede durar aproximadamente 48 horas
      dependiendo el tipo y cantidad de mercancía.
    </li>
    <li>
      <b
        >El tiempo que transcurre desde que la empresa logística recoge la
        mercancía en nuestros almacenes hasta que es embarcada</b
      >
      en el tipo de envío pertinente previamente seleccionado por el cliente.
      Este tiempo dependerá de la empresa logística, la aduana China y otros
      factores externos a Rakumart.
    </li>
    <li>El tiempo de transporte de cada <b>método de envío</b>.</li>
  </ul>
  <p><b>Tiempo aproximado de transporte</b> mediante:</p>
  <ul class="listFont">
    <li><b>Transporte Aéreo</b>: entre 10-20 días</li>
    <li><b>Transporte Terrestre</b>: entre 35-45 días</li>
    <li><b>Transporte Marítimo</b>: entre 60-70 días</li>
    <li><b>Transporte Ferroviario</b>: entre 45-55 días</li>
  </ul>
  <p>
    También hay que añadir el tiempo de <b>envío nacional</b>, de 24 a 48 horas,
    desde nuestros almacenes en España hasta la dirección de entrega.
  </p>
  <p>
  *Los tiempos de transporte hacen referencia específicamente al periodo requerido por la compañía logística para transportar la mercancía desde su punto de origen hasta su punto de destino. Por lo tanto, el «tiempo de transporte» no debe confundirse con el «tiempo de entrega total». Estos tiempos son aproximados y pueden variar dependiendo de factores externos como el clima, regulaciones aduaneras, entre otros. 
  </p>
  <div class="hei20" ></div>
  <div class="tihsiBox">
  <div class="icon">
    <img
      src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202306/648013a7cc740.png"
      alt=""
    />
  </div>
  <div class="textCon">
    <div class="contit">Información importante</div>
    <div class="concon">
    Estos tiempos son aproximados y pueden variar dependiendo de factores externos como el clima, regulaciones aduaneras, entre otros. 
    </div>
  </div>
</div>
</div>
          `,
    menu: "Logística y envíos", //二级分类名字
    menuLevel: 3,
  },
  // ¿Dónde está mi pedido?
  {
    pageName: "¿Dónde está mi pedido?",
    router: "donde-esta-mi-pedido",
    title: '<div class="showBodyBoxTitle">¿Dónde está mi pedido?</div>',
    answer: `<div  class="showBodyBoxAnswer">Si tu pedido no ha sido enviado, puedes consultar el estado de tu pedido accediendo a tu panel de control. En la parte central de la pantalla de inicio encontrarás los “Acceso directo al estado de tus pedidos”. En ellos podrás controlar el estado en que se encuentra tu pedido.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      Puedes ver más sobre los estado de tus pedidos haciendo
      <a
        href="/cual-es-el-significado-de-los-diferentes-estados-de-pedido"
        >clic aquí</a
      >.
    </p>
    <p>
      Si tu pedido ha sido enviado puedes consultar el número de
      seguimiento internacional:
    </p>
    <ul class="listFont">
      <li>Accede a tu panel de control</li>
      <li>
        Haz clic en el apartado “Gestión de pedidos” que
        encontrarás en el menú lateral de tu panel
      </li>
      <li>Haz clic en apartado “Logística y envíos”</li>
      <li>
        En esta pestaña podrás ver el estado en que se encuentra
        el envío de tu pedido.
      </li>
      <li>Haz clic en código “Número de entrega”</li>
      <li>
        En la parte inferior encontrarás los detalles del envío y
        el número de seguimiento
      </li>
      <li>
        Haz el seguimiento de tu envío en cualquier página de
        seguimiento de parcelas, por ejemplo:
      </li>
      <li style="list-style: none;">
        <a
          href="https://www.ups.com/track?loc=es_ES&requester=ST/"
          >https://www.ups.com/track?loc=es_ES&requester=ST/</a
        >
      </li>
    </ul>
    <p>
      Si tienes algún problema o pregunta sobre el estado de tu
      pedido, no dudes en ponerte en contacto con nuestro servicio
      de atención al cliente. Puedes hacerlo a través del chat en
      vivo en nuestra página web o por correo electrónico. Nuestro
      equipo de atención al cliente estará encantado de ayudarte a
      resolver cualquier problema que puedas tener con tu pedido.
    </p>
  </div>
          `,
    menu: "Logística y envíos", //二级分类名字
    menuLevel: 3,
  },

  // ¿Cuándo puedo obtener el número de seguimiento?
  {
    pageName: "¿Cuándo puedo obtener el número de seguimiento?",
    router: "cuando-puedo-obtener-el-numero-de-seguimiento",
    title:
      '<div class="showBodyBoxTitle">¿Cuándo puedo obtener el número de seguimiento?</div>',
    answer: `<div  class="showBodyBoxAnswer">Cuando nuestro equipo en China haya procedido con el envío internacional de tu pedido, recibirás un correo electrónico con la información y el número de seguimiento correspondiente.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      También recibirás una alerta en tu panel de control con la
      misma información.
    </p>
    <p>
      Por otro lado, también podrás acceder al número de
      seguimiento desde tu panel de control:
    </p>
    <Ul class="listFont">
      <li>Accede a tu panel de control</li>
      <li>
        Haz clic en el apartado “Gestión de pedidos” que
        encontrarás en el menú lateral de tu panel
      </li>
      <li>Haz clic en apartado “Logística y envíos”</li>
      <li>
        En esta pestaña podrás ver el estado en que se encuentra
        el envío de tu pedido.
      </li>
      <li>Haz clic en código “Número de entrega”</li>
      <li>
        En la parte inferior encontrarás los detalles del envío y
        el número de seguimiento
      </li>
      <li>
        Haz el seguimiento de tu envío en cualquier página de
        seguimiento de parcelas, por ejemplo:
      </li>
      <li style="list-style: none;">
        <a
          href="https://www.ups.com/track?loc=es_ES&requester=ST/"
          >https://www.ups.com/track?loc=es_ES&requester=ST/</a
        >
      </li>
    </Ul>
    <p>
      Si no has recibido el número de seguimiento después de que
      nuestro equipo en China haya procedido con el envío
      internacional de tu pedido y haya pasado un tiempo
      razonable, por favor, contáctanos y nuestro equipo de
      atención al cliente te ayudará a resolver cualquier problema
      que puedas tener.
    </p>
  </div>
          `,
    menu: "Logística y envíos", //二级分类名字
    menuLevel: 3,
  },

  // Desglose de costes de envío
  {
    pageName: "Desglose de costes de envío",
    router: "desglose-de-costes-de-envio",
    title: '<div class="showBodyBoxTitle">Desglose de costes de envío</div>',
    answer: `<div  class="showBodyBoxAnswer">Los costes del flete internacional se desglosan en cuatro componentes principales que se deben tener en cuenta:</div>`,
    content: `
    <div class="allFontPage">
    <ul class="listFont numStyle">
      <li>
        <b>Envío internacional:</b> Este es el costo de
        transportar la mercancía desde el país de origen (China)
        hasta el destino final en el extranjero. Este costo puede
        variar según la distancia, el peso y el tamaño de la
        mercancía.
      </li>
      <li>
        <b>Transporte en España:</b> Una vez que la mercancía
        llega a España, se debe transportar hasta su destino
        final. Rakumart trabaja con un partner logístico (GLS) que
        realiza el transporte “express” desde nuestros almacenes
        en Barcelona.
      </li>
      <li>
        <b>Aranceles:</b> Los aranceles son los impuestos que se
        aplican a los bienes importados. Estos impuestos son
        establecidos por el país importador y pueden variar según
        el tipo de producto y el país de origen.
      </li>
      <li>
        <b>IVA:</b> El Impuesto sobre el Valor Añadido (IVA) es un
        impuesto que se aplica al valor de los bienes y servicios.
        En el caso del flete internacional, el IVA se aplica al
        valor total de los costes de la mercancía, el envío,
        transporte y aranceles.
      </li>
    </ul>
    <p>
      Es importante tener en cuenta que estos costes pueden variar
      según el tipo y cantidad de mercancía que se está
      transportando. Por lo tanto, es esencial comprender estos
      costes y planificar cuidadosamente antes de realizar
      cualquier envío internacional de mercancías.
    </p>
  </div>
          `,
    menu: "Logística y envíos", //二级分类名字
    menuLevel: 3,
  },

  // Transporte de mercancías peligrosas
  {
    pageName: "Transporte de mercancías peligrosas",
    router: "transporte-de-mercancias-peligrosas",
    title:
      '<div class="showBodyBoxTitle">Transporte de mercancías peligrosas</div>',
    answer: `<div  class="showBodyBoxAnswer">El transporte de mercancías peligrosas es una actividad muy importante en el mundo actual, ya que permite el traslado de productos que son fundamentales para la economía y la sociedad en general. Sin embargo, también es una actividad que conlleva riesgos, ya que estas mercancías tienen características y propiedades que pueden amenazar la vida y la salud humana, causar daños irreparables al medio ambiente o dañar objetos materiales.</div>`,
    content: `
    <div class="allFontPage">
                  <p>
                    Dentro de las mercancías peligrosas se encuentran una gran
                    variedad de productos, entre los que se encuentran animales
                    y productos animales, productos vegetales, grasas y aceites
                    animales o vegetales, preparados alimenticios, metales
                    comunes, instrumentos de precisión, armas y municiones,
                    obras de arte, entre otros.
                  </p>
                  <p>
                    Es importante tener en cuenta que el transporte de
                    mercancías peligrosas debe ser regulado y controlado por
                    organismos especializados, con el fin de minimizar los
                    riesgos asociados a esta actividad y garantizar la seguridad
                    de las personas, el medio ambiente y los bienes materiales.
                  </p>
                </div>
        `,
    menu: "Logística y envíos", //二级分类名字
    menuLevel: 3,
  },

  // Selección de empresas de transporte internacional
  {
    pageName: "Selección de empresas de transporte internacional",
    router: "seleccion-de-empresas-de-transporte-internacional",
    title:
      '<div class="showBodyBoxTitle">Selección de empresas de transporte internacional</div>',
    answer: `<div  class="showBodyBoxAnswer">En Rakumart, nos aseguramos de elegir cuidadosamente canales logísticos internacionales fiables, rápidos y económicamente eficientes para el envío de tus mercancías. Trabajamos continuamente en la evaluación de nuestros partners logísticos para garantizar que ofrezcan la mejor calidad de servicio posible.</div>`,
    content: `
    <div class="allFontPage">
    <p>
    Para seleccionar nuestros partners logísticos y rutas de transporte, consideramos varios factores. Algunos de estos factores incluyen el costo del flete, la puntualidad del transporte, la estabilidad del servicio y la satisfacción del cliente. Nos aseguramos de que los proveedores de transporte que elijamos cumplan con los estándares de calidad que esperan nuestros clientes.
    </p>
    <p>
    Además, nos mantenemos actualizados constantemente sobre nuevas opciones de transporte y rutas de envío. Esto nos permite ofrecer a nuestros clientes las mejores soluciones de transporte disponibles en nuestra plataforma. Siempre estamos trabajando para mejorar y expandir nuestra selección de partners logísticos para garantizar que nuestros clientes reciban un servicio de alta calidad y un envío seguro y rápido.
    </p>
    <p>
    ¡En Rakumart estamos comprometidos con tu satisfacción total y siempre trabajamos para ofrecerte los mejores servicios logísticos internacionales!
    </p>
  </div>
          `,
    menu: "Logística y envíos", //二级分类名字
    menuLevel: 3,
  },

  // elección de empresas de transporte in
  {
    pageName: "elección de empresas de transporte in",
    router: "seleccion-de-empresas-de-transporte-internacional",
    title:
      '<div class="showBodyBoxTitle">elección de empresas de transporte in</div>',
    answer: `<div  class="showBodyBoxAnswer">En Rakumart, nos aseguramos de elegir cuidadosamente canales logísticos internacionales fiables, rápidos y económicamente eficientes para el envío de tus mercancías. Trabajamos continuamente en la evaluación de nuestros partners logísticos para garantizar que ofrezcan la mejor calidad de servicio posible.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      Para seleccionar nuestros partners logísticos y rutas de
      transporte, consideramos varios factores. Algunos de estos
      factores incluyen el costo del flete, la puntualidad del
      transporte, la estabilidad del servicio y la satisfacción
      del cliente. Nos aseguramos de que los proveedores de
      transporte que elijamos cumplan con los estándares de
      calidad que esperan nuestros clientes.
    </p>
    <p>
      Además, nos mantenemos actualizados constantemente sobre
      nuevas opciones de transporte y rutas de envío. Esto nos
      permite ofrecer a nuestros clientes las mejores soluciones
      de transporte disponibles en nuestra plataforma. Siempre
      estamos trabajando para mejorar y expandir nuestra selección
      de partners logísticos para garantizar que nuestros clientes
      reciban un servicio de alta calidad y un envío seguro y
      rápido.
    </p>
    <p>
      ¡En Rakumart estamos comprometidos con tu satisfacción total
      y siempre trabajamos para ofrecerte los mejores servicios
      logísticos internacionales!
    </p>
  </div>
          `,
    menu: "Logística y envíos", //二级分类名字
    menuLevel: 3,
  },

  // Despacho de las mercancías en aduanas
  {
    pageName: "Despacho de las mercancías en aduanas",
    router: "despacho-de-las-mercancias-en-aduanas",
    title:
      '<div class="showBodyBoxTitle">Despacho de las mercancías en aduanas</div>',
    answer: `<div  class="showBodyBoxAnswer">El despacho de aduana de las mercancías se determinará de acuerdo con el método de despacho de aduana que elija cuando solicite la entrega (marítimo, aéreo o terrestre).</div>`,
    content: `
    <div class="allFontPage">
    <p>
      Rakumart a se encargará de los asuntos de despacho de aduana
      relevantes. Esto incluye la presentación de la documentación
      necesaria, la comunicación con las autoridades pertinentes y
      el pago de los impuestos y tasas aplicables.
    </p>
    <p>
      Sin embargo, entendemos que algunos de nuestros clientes
      pueden preferir encargarse de los trámites aduaneros por su
      cuenta. Por lo tanto, les ofrecemos la opción de hacerlo. Si
      decides realizar los trámites aduaneros por tu cuenta, te
      proporcionaremos toda la información necesaria y estaremos a
      tu disposición para responder cualquier pregunta que puedas
      tener.
    </p>
  </div>
          `,
    menu: "Logística y envíos", //二级分类名字
    menuLevel: 3,
  },
  // ¿Dónde enviáis?
  {
    pageName: "¿Dónde enviáis?",
    router: "donde-enviais",
    title: '<div class="showBodyBoxTitle">¿Dónde enviáis?</div>',
    answer: `<div  class="showBodyBoxAnswer">En <a href="/">rakumart.es</a> solo realizamos importaciones desde China a España.</div>`,
    content: `
      
          `,
    menu: "Logística y envíos", //二级分类名字
    menuLevel: 3,
  },

  // ¿Por qué no recibí un correo electrónico sobre el envío de mi pedido?
  {
    pageName:
      "¿Por qué no recibí un correo electrónico sobre el envío de mi pedido?",
    router:
      "por-que-no-recibi-un-correo-electronico-sobre-el-envio-de-mi-pedido",
    title:
      '<div class="showBodyBoxTitle">¿Por qué no recibí un correo electrónico sobre el envío de mi pedido?</div>',
    answer: `<div  class="showBodyBoxAnswer">Al realizar el segundo pago para tu pedido en Rakumart, recibirás un correo electrónico de confirmación de manera automática. Este correo contiene información importante relacionada con tu pedido.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      Te recordamos que, en caso de no encontrar el correo de
      confirmación en tu bandeja de entrada, debes revisar tu
      carpeta de spam. Si encuentras el correo aquí, por favor
      marca los correos de Rakumart como correo de confianza para
      que no se filtren en la carpeta de spam en el futuro.
    </p>
    <p>
      Si después de revisar ambas carpetas aún no encuentras el
      correo de confirmación, por favor no dudes en contactar a
      nuestro equipo de soporte. Estaremos encantados de ayudarte
      a resolver cualquier problema que tengas con tu pedido.
    </p>
  </div>
          `,
    menu: "Logística y envíos", //二级分类名字
    menuLevel: 3,
  },

  // ¿Por qué se retrasa mi pedido?
  {
    pageName: "¿Por qué se retrasa mi pedido?",
    router: "por-que-se-retrasa-mi-pedido",
    title: '<div class="showBodyBoxTitle">¿Por qué se retrasa mi pedido?</div>',
    answer: `<div  class="showBodyBoxAnswer">Debido a los eventos internacionales ajenos a nuestra empresa, asuntos aduaneros entre países y otros motivos, puede ser posible que sucedan retrasos en la entrega. Estos eventos podrían incluir huelgas, inclemencias del tiempo, o problemas técnicos. Aunque Rakumart siempre trabaja arduamente para garantizar que haya demoras e interrupciones mínimas en nuestros servicios, hay factores en los que nuestras acciones son limitadas.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      Sin embargo, tenga la seguridad de que en caso de cualquier
      retraso, nuestro equipo de atención al cliente trabajará con
      diligencia para resolver cualquier problema que surja y
      asegurarse de que su experiencia con Rakumart sea lo más
      satisfactoria posible. Además, mantendremos una comunicación
      clara y transparente con usted durante todo el proceso.
    </p>
    <p>
      Aunque no podemos controlar todos los factores externos que
      pueden afectar la entrega, queremos asegurarle que siempre
      trabajaremos arduamente para minimizar cualquier impacto en
      nuestros servicios y en su experiencia de compra.
    </p>
  </div>
          `,
    menu: "Logística y envíos", //二级分类名字
    menuLevel: 3,
  },

  // ¿Por qué no hay actualización de seguimiento?
  {
    pageName: "¿Por qué no hay actualización de seguimiento?",
    router: "por-que-no-hay-actualizacion-de-seguimiento",
    title:
      '<div class="showBodyBoxTitle">¿Por qué no hay actualización de seguimiento?</div>',
    answer: `<div  class="showBodyBoxAnswer">Como se trata de envíos internacionales entre continentes, es posible que no veas actualizaciones a través de métodos de seguimiento locales hasta que la aduana local autorice el paquete y se le permita ingresar Europa o España.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      Si tu pedido aún está por debajo del plazo de entrega
      estimado, te rogamos esperar pacientemente. Una vez que se
      haya completado este proceso, verás actualizaciones de
      seguimiento.
    </p>
  </div>
          `,
    menu: "Logística y envíos", //二级分类名字
    menuLevel: 3,
  },

  // ¿Cuáles son las diferencias entre los tres tipos de envío disponibles?
  {
    pageName:
      "¿Cuáles son las diferencias entre los tres tipos de envío disponibles?",
    router:
      "cuales-son-las-diferencias-entre-los-tres-tipos-de-envio-disponibles",
    title:
      '<div class="showBodyBoxTitle">¿Cuáles son las diferencias entre los tres tipos de envío disponibles?</div>',
    answer: `<div  class="showBodyBoxAnswer">En <a href="/">rakumart.es</a> ofrecemos varias opciones de envío para que nuestros clientes puedan elegir la que mejor se adapte a sus necesidades.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      El <b>envío aéreo</b> es ideal para aquellos que necesitan
      recibir su pedido lo antes posible. Con un tiempo de entrega
      estimado de <b>8 a 10 días</b>. El coste del envío aéreo es
      el más elevado.
    </p>
    <p>
      El <b>envío terrestre</b> es una opción más económica que el
      envío aéreo, con un tiempo de entrega estimado de
      <b>20 a 25 días</b>. Esta opción está disponible para cargas
      mínimas de 13kg, y es una buena opción para aquellos
      clientes que no necesitan recibir sus productos con
      urgencia.
    </p>
    <p>
      El <b>envío marítimo</b> es la opción más económica para
      nuestros clientes. Con un tiempo de entrega estimado de
      <b>40 a 45 días</b>, esta opción requiere un volumen mínimo
      de mercancía de 10m3. Sin embargo, es una buena opción para
      aquellos clientes que desean ahorrar en costos de envío al
      ser la opción más económica.
    </p>
    <p>
      Por último, y como método excepcional para algunos casos, el
      <b>envío ferroviario</b>. Con un tiempo de entrega de
      <b>40 a 45</b> días y un volumen mínimo de 21kg y con
      tarifas similares a las del envío marítimo.
    </p>
  </div>
          `,
    menu: "Logística y envíos", //二级分类名字
    menuLevel: 3,
  },

  // ¿Puedo enviar mi pedido directamente a las bodegas de Amazon?
  {
    pageName: "¿Puedo enviar mi pedido directamente a las bodegas de Amazon?",
    router: "puedo-enviar-mi-pedido-directamente-a-las-bodegas-de-amazon",
    title:
      '<div class="showBodyBoxTitle">¿Puedo enviar mi pedido directamente a las bodegas de Amazon?</div>',
    answer: `<div  class="showBodyBoxAnswer">
      En <a href="/">rakumart.es</a> ofrecemos un servicio de importación y de gestión de envío a las bodegas de Amazon en España. ¿Cómo funciona? Cuando los pedidos llegan a España, los recibimos en nuestros almacenes, donde quedarán a la espera de ser recogidos por la agencia de transporte enviada por el propio cliente. Esta agencia será la encargada de transportar el pedido hasta las bodegas de Amazon.    </div>`,
    content: `
      <div class="allFontPage">  
  
     <p>
     En Rakumart nos encargamos de recoger el pedido, prepararlo, guardarlo en nuestros almacenes y entregarlo a la agencia para su correcto envío a las bodegas de Amazon.
     </p>
     <p>
     Además, ofrecemos un servicio adicional de etiquetado de producto desde nuestros almacenes en China. Consulta las tarifas de etiquetado y otros servicios adicionales haciendo  <a href="https://www.rakumart.es/servicios-adicionales">clic aquí</a>.
     </p>
     <P>
     También, ofrecemos la opción de envío del pedido a la dirección indicada por el cliente o de recogida física en nuestros almacenes de Badalona (Barcelona, España).
     </P>
     <p>
     Si tienes alguna pregunta o necesitas más información sobre el envío a las bodegas de Amazon, no dudes en ponerte en contacto con nuestro centro de atención al cliente. Estamos aquí para ayudarte en todo lo que necesites y asegurarnos de que tengas la mejor experiencia.
     </p>
     <p>
     Es muy importante remarcar que Rakumart no gestiona la agencia de transporte de envío a las bodegas de Amazon. Es el cliente quien debe hacerse cargo de esta gestión.
     </p>
    </div>
          `,
    menu: "Logística y envíos", //二级分类名字
    menuLevel: 3,
  },

  // ¿Qué garantías ofrece Rakumart?
  {
    pageName: "¿Qué garantías ofrece Rakumart?",
    router: "que-garantias-ofrece-rakumart",
    title:
      '<div class="showBodyBoxTitle">¿Qué garantías ofrece Rakumart?</div>',
    answer: `<div  class="showBodyBoxAnswer">Realizar importaciones con Rakumart es sinónimo de garantía ya que te acompañamos durante todo el proceso. Nuestros clientes compran su mercancía a una empresa con sede y facturación en España, ya que Rakumart se encarga de realizar la compra y la importación directamente desde China. Esto permite que nuestros clientes se beneficien garantías como:</div>`,
    content: `
    <div class="allFontPage">
    <ul class="listFont">
      <li>
        <b>Garantía de productos:</b> Mantenemos un contacto
        estrecho con el fabricante lo cual nos permite saber mucha
        información relacionada con los productos de cada pedido.
        Además, cuando la mercancía es recibida en nuestros
        almacenes en China, se lleva a cabo un control de calidad
        estándar que garantice la satisfacción de nuestros
        clientes.
      </li>
      <div class="hei30"></div>
      <li>
        <b>Garantía de envío:</b> En Rakumart nos preocupamos por
        la calidad del servicio que ofrecemos, es por eso que
        garantizamos la entrega de los productos en perfecto
        estado. Además, nuestro compromiso con el cliente va más
        allá de la simple entrega, ya que nos aseguramos de que el
        proceso de envío sea lo más eficiente posible. En caso de
        que el producto llegue en mal estado o exceda el tiempo de
        entrega exageradamente, nuestro equipo de atención al
        cliente se encargará de gestionar el reembolso del valor
        del producto de manera inmediata y sin costos adicionales
        para el cliente. De esta manera, queremos asegurarnos de
        que nuestros clientes estén completamente satisfechos con
        su experiencia de compra en Rakumart.
      </li>
      <div class="hei30"></div>
      <li>
        <b>Garantía de producto defectuoso:</b> Todos los
        productos ofertados cuentan con un periodo de garantía de
        3 años, conforme a los criterios y condiciones descritas
        en el Real Decreto Legislativo 1/2007, de 16 de noviembre,
        por el que se aprueba el texto refundido de la Ley General
        para la Defensa de los Consumidores y Usuarios y otras
        leyes complementarias, salvo casos excepcionales en los
        que puede ser de distinta duración. En caso de que la Ley
        de Garantía establezca que el producto debe ser enviado al
        fabricante para gestionar dicha garantía, el equipo de
        Rakumart le indicará cómo proceder a través de correo
        electrónico. El derecho de garantía será aplicable siempre
        que se trate de un defecto de fábrica y no del uso
        inadecuado del artículo o por desgaste normal de uso.
        Puedes encontrar más información en el siguiente enlace:
        <a
          href="https://www.rakumart.es/condiciones-generales-de-contratacion"
          style="text-decoration: none;"
          >https://www.rakumart.es/condiciones-generales-de-contratacion</a
        >
      </li>
      <div class="hei30"></div>
      <li>
        <b>Garantía de importación:</b> Rakumart se encarga de
        realizar la importación y gestionar todos los trámites y
        documentación necesarios para despachar la mercancía de
        nuestros clientes. Nos aseguramos de cumplir con todas las
        regulaciones y requisitos aduaneros para que la
        importación se realice de manera exitosa y lo más ágil
        posible. Al realizar nosotros mismos la importación,
      </li>
    </ul>
  </div>
          `,
    menu: "Garantías y posventa", //二级分类名字
    menuLevel: 3,
  },
  // Política de muestras en Rakumart
  {
    pageName: "Política de muestras en Rakumart",
    router: "politica-de-muestras-en-rakumart",
    title:
      '<div class="showBodyBoxTitle">Política de muestras en Rakumart</div>',
    answer: `<div  class="showBodyBoxAnswer">En Rakumart, entendemos la importancia de tomar decisiones informadas al momento de importar productos desde China. Queremos compartir contigo una parte fundamental de nuestro proceso: la adquisición de muestras. Aunque nuestro objetivo es ayudarte a encontrar proveedores confiables, es vital que comprendas nuestra política relacionada con las muestras de baja calidad o defectuosas.</div>`,
    content: `
    <div class="allFontPage">
    <ul class="listFont" style="margin-bottom:14px">
      <li>
        <b>Detectando Calidad desde el inicio:</b> Nuestro enfoque es garantizar que cada producto que elijas tenga la calidad que mereces. Las muestras juegan un papel crucial en este proceso. Al comprar muestras, te brindamos la oportunidad de evaluar la calidad, el diseño y otros aspectos antes de realizar una compra a mayor escala. Estas muestras, incluso si son defectuosas o de baja calidad, te ayudan a detectar y descartar proveedores que no cumplen con los estándares exigidos.
      </li>
      <li>
        <b>Responsabilidad compartida:</b> Es importante recordar que, como cliente de Rakumart, compartimos la responsabilidad de garantizar la calidad de los productos. Las muestras, aunque puedan presentar defectos o baja calidad, están diseñadas para cumplir un propósito específico: identificar proveedores poco confiables. Te brindamos la oportunidad de tomar decisiones basadas en la información que obtengas de estas muestras, lo que te permite elegir proveedores que se alineen con tus estándares de calidad.
      </li>
      <li>
        <b>Nuestra política:</b> Debemos ser transparentes respecto a nuestra política con respecto a las muestras de baja calidad o defectuosas. <b>Rakumart no se hace responsable por la devolución o reembolso de muestras</b> que no cumplan con los estándares de calidad esperados. Sin embargo, nos comprometemos a brindarte toda la información necesaria para tomar decisiones acertadas y ayudarte a encontrar proveedores confiables y productos de alta calidad.
      </li>     
    </ul>
    <p>
    En Rakumart, estamos aquí para apoyarte en cada paso del proceso de importación. Comprender nuestra política con respecto a las muestras de baja calidad es esencial para tomar decisiones informadas y garantizar la calidad de los productos que importas. Apreciamos tu compromiso en trabajar junto a nosotros para seleccionar proveedores confiables y productos que cumplan tus expectativas. Juntos, lograremos que tus importaciones sean un éxito rotundo.
    </p>
  </div>
          `,
    menu: "Garantías y posventa", //二级分类名字
    menuLevel: 3,
  },
  // ¿Hacéis control de calidad de la mercancía?
  {
    pageName: "¿Hacéis control de calidad de la mercancía?",
    router: "haceis-control-de-calidad-de-la-mercancia",
    title:
      '<div class="showBodyBoxTitle">¿Hacéis control de calidad de la mercancía?</div>',
    answer: `<div  class="showBodyBoxAnswer">Si, realizamos un control de calidad estándar siempre que recibimos la mercancía desde fábrica.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      El control de calidad estándar que realizamos cuando
      recibimos la mercancía desde fábrica incluye las siguientes
      comprobaciones:
    </p>
    <ul class="listFont">
      <li>
        <b>Comprobación de la entrada del pedido</b>: verificamos
        que el producto recibido coincide con el producto que
        hemos pedido.
      </li>
      <li>
        <b>Comprobación de daños visibles</b>: revisamos
        visualmente el producto para detectar cualquier daño o
        defecto evidente.
      </li>
      <li>
        <b>Verificación de cualquier diferencia</b> importante
        entre la página del producto y el producto recibido:
        comprobamos que el producto recibido coincide con la
        descripción y las especificaciones que aparecen en la
        página del producto. Si hay alguna diferencia importante,
        lo investigamos y solucionamos de forma adecuada.
      </li>
    </ul>
  </div>
          `,
    menu: "Garantías y posventa", //二级分类名字
    menuLevel: 3,
  },

  // ¿Qué pasa si los productos no superan el control de calidad?
  {
    pageName: "¿Qué pasa si los productos no superan el control de calidad?",
    router: "que-pasa-si-los-productos-no-superan-el-control-de-calidad",
    title:
      '<div class="showBodyBoxTitle">¿Qué pasa si los productos no superan el control de calidad?</div>',
    answer: `<div  class="showBodyBoxAnswer">Si los productos no superan el control de calidad, se realizan todas las gestiones necesarias para garantizar la satisfacción de nuestros clientes.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      En primer lugar, se contacta con el fabricante para
      gestionar el cambio o la devolución de la mercancía. Si se
      opta por el cambio, nos aseguramos de que el producto
      sustituto cumpla con los altos estándares de calidad que
      exigimos. En caso de que la mercancía no se pueda cambiar,
      informamos con rapidez a nuestros clientes y les ofrecemos
      diferentes opciones para resolver la situación.
    </p>
    <p>
      Una de las alternativas es buscar otro proveedor que ofrezca
      productos similares y de calidad, mientras que otra opción
      es proceder con la devolución total del importe. De esta
      manera, aseguramos que nuestros clientes siempre queden
      satisfechos.
    </p>
  </div>
          `,
    menu: "Garantías y posventa", //二级分类名字
    menuLevel: 3,
  },

  // ¿Cómo verificáis a los proveedores?
  {
    pageName: "¿Cómo verificáis a los proveedores?",
    router: "como-verificais-a-los-proveedores",
    title:
      '<div class="showBodyBoxTitle">¿Cómo verificáis a los proveedores?</div>',
    answer: `<div  class="showBodyBoxAnswer">En Rakumart, nos tomamos muy en serio la calidad de nuestros proveedores y su confiabilidad. Para garantizar esto, llevamos a cabo un riguroso proceso de selección y calificación de proveedores antes de incluir sus productos en nuestra plataforma. Este proceso incluye la evaluación de la calidad de los productos, la capacidad de los proveedores para cumplir con los plazos de entrega y la comunicación clara con nuestros agentes. Además, como hemos estado operando internacionalmente desde China durante años, específicamente en países como Japón, Corea y Brasil, estamos muy familiarizados con las operaciones comerciales en diferentes partes del mundo y nos aseguramos de tener en cuenta todas las necesidades de nuestros clientes internacionales.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      Nuestra experiencia en la industria nos ha permitido
      construir una amplia y actualizada base de datos de
      proveedores de calidad y confianza, la cual seguimos
      mejorando día a día. También nos aseguramos de mantener una
      comunicación constante con nuestros proveedores para
      garantizar que estén al tanto de nuestras expectativas y de
      cualquier cambio en los requisitos de calidad.
    </p>
  </div>
          `,
    menu: "Garantías y posventa", //二级分类名字
    menuLevel: 3,
  },
  // ¿Los productos cuentan con los certificados necesarios para cumplir con las normativas de Europa?
  {
    pageName:
      "¿Los productos cuentan con los certificados necesarios para cumplir con las normativas de Europa?",
    router:
      "los-productos-cuentan-con-los-certificados-necesarios-para-cumplir-con-las-normativas-de-europa",
    title:
      '<div class="showBodyBoxTitle">¿Los productos cuentan con los certificados necesarios para cumplir con las normativas de Europa?</div>',
    answer: `<div  class="showBodyBoxAnswer">Nuestro compromiso es garantizar que los productos que importamos a Europa cumplan con los más altos estándares de calidad y seguridad establecidos por la Unión Europea. Por esta razón, nos aseguramos de que todos los productos que importamos estén en plena conformidad con la normativa de la UE y puedan ser comercializados en el mercado europeo.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      Si al realizar la cotización de un pedido detectamos
      productos que carecen de certificados, nuestro equipo se
      encarga de buscar otro proveedor que cuente con dichos
      certificados y se comunica al cliente.
    </p>
  </div>
          `,
    menu: "Garantías y posventa", //二级分类名字
    menuLevel: 3,
  },
  // ¿Qué pasa si recibí un artículo defectuoso?
  {
    pageName: "¿Qué pasa si recibí un artículo defectuoso?",
    router: "que-pasa-si-recibi-un-articulo-defectuoso",
    title:
      '<div class="showBodyBoxTitle">¿Qué pasa si recibí un artículo defectuoso?</div>',
    answer: `<div  class="showBodyBoxAnswer">Si recibes un artículo dañado, defectuoso o incorrecto, comunícate con nuestro equipo de atención al cliente dentro de los 7 días posteriores a la entrega del pedido. Es importante que nos informes del problema lo antes posible para que podamos tomar las medidas necesarias y resolver el problema de la forma más rápida y eficiente posible.</div>`,
    content: `
    <div class="allFontPage">
    <p>
      Nuestro equipo de atención al cliente está siempre
      disponible para ayudarte y asegurarse de que estés
      satisfecho con tu compra en todo momento. No dudes en
      ponerte en contacto con nosotros si tienes cualquier
      problema o pregunta sobre tu pedido, estaremos encantados de
      ayudarte en todo lo que podamos.
    </p>
  </div>
          `,
    menu: "Garantías y posventa", //二级分类名字
    menuLevel: 3,
  },
  // ¿Qué pasa si el pedido llega en mal estado a España?
  {
    pageName: "¿Qué pasa si el pedido llega en mal estado a España?",
    router: "que-pasa-si-el-pedido-llega-en-mal-estado-a-espana",
    title:
      '<div class="showBodyBoxTitle">¿Qué pasa si el pedido llega en mal estado a España?</div>',
    answer: `<div  class="showBodyBoxAnswer">En Rakumart nos preocupamos por la calidad del servicio que ofrecemos, es por eso que garantizamos la entrega de los productos en perfecto estado. En caso de que el producto llegue en mal estado, nuestro equipo de atención al cliente se encargará de gestionar el reembolso del valor del producto de manera inmediata y sin costos adicionales para el cliente. De esta manera, queremos asegurarnos de que nuestros clientes estén completamente satisfechos con su experiencia de compra en Rakumart.</div>`,
    content: `
      
          `,
    menu: "Garantías y posventa", //二级分类名字
    menuLevel: 3,
  },
  // ¿Cuál es la política de devolución y reembolso?
  {
    pageName: "¿Cuál es la política de devolución y reembolso?",
    router: "cual-es-su-politica-de-devolucion-y-reembolso",
    title:
      '<div class="showBodyBoxTitle">¿Cuál es la política de devolución y reembolso?</div>',
    answer: `<div  class="showBodyBoxAnswer">En el caso concreto de Rakumart, al realizar importaciones específicas a petición del cliente desde un tercer país fuera del espacio económico europeo, solo se podrá llevar a cabo la cancelación de la compra si aún no se ha iniciado el transporte de la mercancía, no siendo relevante la cantidad de la misma, o la mercancía está defectuosa.</div>`,
    content: `
    <div class="allFontPage">
    <p>
    En el caso concreto de ELOSUNG EUROPE, al realizar importaciones específicas a petición del cliente desde un tercer país fuera del espacio económico europeo, solo se podrá llevar a cabo la cancelación de la compra bajo las políticas que ofrezcan los fabricantes. El cliente puede solicitar a ELOSUNG EUROPE que actúe como intermediario en las negociaciones con el vendedor, sin embargo, ELOSUNG EUROPE no asume responsabilidad por los resultados finales de dichas negociaciones. Todos los costos asociados con la resolución del problema serán responsabilidad del cliente. (El cliente deberá confirmar con el vendedor la posibilidad de devolución o cambio del producto, y será responsable de los gastos de envío nacionales que se generen), no siendo relevante la cantidad de la misma, o la mercancía está defectuosa.
    </p>
    <p>
    Envíenos un correo electrónico a  <a href="mailto:soporte@rakumart.es">soporte@rakumart.es</a> le proporcionaremos las instrucciones para devolver el producto. El horario de atención al cliente será de lunes a viernes de 09:00h a18:00h. Para la cancelación del pedido o la ampliación del mismo se podrá utilizar el chat instalado en la plataforma.
    </p>
    <p>
    En caso de devolución por defecto en la mercancía se deberá devolver el producto con todos los elementos con los que le fue entregado y que no denote un uso inadecuado del bien o diferente de la naturaleza propia del mismo a la dirección que le hemos facilitado.
    </p>
    <p>
    Tenga en cuenta que no se producirá la devolución de la mercancía si:
    </p>
    <ul class="listFont">
      <li>
      Fallos o daños causados por el uso indebido o descuido (golpes, abolladuras, aplastamientos, etc.).
      </li>
      <li>
      Fallos o daños causados por una reparación o modificación injustificable.
      </li>
      <li>
      Fallos o daños causados por fuego o agua, o un desastre natural como un terremoto.
      </li>
      <li>
      Los cambios estéticos que se produzcan durante el uso y desgaste normal y el envejecimiento.
      </li>
      <li>
      En caso de que la tienda y la fecha de compra no se indiquen en la garantía, o si esta información ha sido reescrita.
      </li>
    </ul>
    <p>
    Esta garantía le asegura que usted puede recibir un reemplazo gratuito de conformidad con los términos establecidos en el presente documento y dentro del término indicado, y no podrá restringir ningún derecho legal del cliente.
    </p>
  
  </div>
          `,
    menu: "Garantías y posventa", //二级分类名字
    menuLevel: 3,
  },

  // // 文件名
  // {
  //   pageName: '文件名称',
  //   router: '路由名字',
  //   title: '<div class="showBodyBoxTitle">文件名字</div>',
  //   answer: `<div  class="showBodyBoxAnswer">回答</div>`,
  //   content: `
  //     内容
  //         `,
  //   menu: 'Garantías y posventa', //二级分类名字
  //   menuLevel: 3
  // },
];

// 导出用代码
// let ii = []

// var stringToHTML = function (str) {
//   if (str) {
//     var dom = document.createElement('div');
//     dom.innerHTML = str;
//   }
//   return dom || '';
// };
// data.forEach((item) => {
//   if (item.menuLevel == 3) {
//     ii.push({
//       pageName: stringToHTML(item.pageName).innerText,
//       router: (() => {
//         let str = ''
//         str += (stringToHTML(item.answer).innerText + '\n')
//         str += (stringToHTML(item.content).innerText + '\n')
//         return str
//       })()
//     })

//   }

// })
// console.log(ii);
// const handleExportExcel = () => {
//   require.ensure([], () => {
//     const { export_json_to_excel } = require("@/utlis/Export2Excel");
//     const tHeader = [
//       "q",
//       "a"
//     ];
//     const filterVal = [
//       "pageName",
//       "router",
//     ];
//     const list = ii; //把data里的tableData存到list
//     const ddata = formatJson(filterVal, list);
//     export_json_to_excel(tHeader, ddata, "客户列表");
//   });
// }

// const formatJson = (filterVal, jsonData) => {
//   return jsonData.map((v) => filterVal.map((j) => v[j]));
// }
// setTimeout(() => {
//   handleExportExcel()
// }, 1000);
export default data;
