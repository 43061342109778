<template>
  <ul class="leftMenu">
    <li
      :class="{ active: actMenu == 'Empieza en Rakumart' }"
      @click="$fun.routerToPage('/empieza-en-rakumart')"
    >
      <span>Empieza en Rakumart</span>
      <i class="el-icon-arrow-right"></i>
    </li>
    <li
      :class="{ active: actMenu == 'Mi cuenta' }"
      @click="$fun.routerToPage('/mi-cuenta-3')"
    >
      <span>Mi cuenta</span>
      <i class="el-icon-arrow-right"></i>
    </li>
    <li
      :class="{ active: actMenu == 'Hacer un pedido' }"
      @click="$fun.routerToPage('/hacer-un-pedido')"
    >
      <span>Hacer un pedido</span>
      <i class="el-icon-arrow-right"></i>
    </li>
    <!-- 2023/3/17 这里的menu是未知,示例网站打开错误了 -->
    <li
      :class="{ active: actMenu == 'Pagos' }"
      @click="$fun.routerToPage('/pagos')"
    >
      <span>Pagos</span>
      <i class="el-icon-arrow-right"></i>
    </li>
    <li
      :class="{ active: actMenu == 'Logística y envíos' }"
      @click="$fun.routerToPage('/logistica-y-envio')"
    >
      <span>Logística y envíos</span>
      <i class="el-icon-arrow-right"></i>
    </li>
    <li
      :class="{ active: actMenu == 'Garantías y posventa' }"
      @click="$fun.routerToPage('/garantias-y-posventa')"
    >
      <span>Garantías y posventa</span>
      <i class="el-icon-arrow-right"></i>
    </li>
  </ul>
</template>
<script>
export default {
  props: {
    actMenu: {
      default: "",
    },
  },
  data() {
    return {};
  },
  components: {},
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.leftMenu {
  padding: 10px;
  width: 215px;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 20px;
    cursor: pointer;
  }
  li.active {
    color: #1a289d;
  }
}
</style>
