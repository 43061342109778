<template>
  <div class="contactUsModel">
    <div class="obody">
      <h1>¿No encuentras las respuestas que estás buscando?</h1>
      <h2>Contacta con nosotros, aquí estamos para ayudarte.</h2>
      <button @click="$fun.clickJs()">
        Contacta con atención al cliente de Rakumart
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.contactUsModel {
  background-color: #f6f6f8;
  height: 338.69px;
  display: flex;
  justify-content: center;
  align-items: center;
  .obody {
    width: 1060px;
    * {
      text-align: center;
    }

    h1 {
      color: #3c4043;
      font-size: 21px;
      font-weight: 500;
      line-height: 1.5em;
      height: 30px;
      margin-bottom: 5px;
    }
    h2 {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 34px;
    }
    button {
      width: 492px;
      height: 60px;
      border: solid 2px #1a73e8;
      color: #1a73e8;
      font-size: 15px;
      font-weight: 600;
      border-radius: 4px;
      transform: 0.3s;
      display: block;
      margin: 0 auto;
      &:hover {
        color: #ffffff;
        background-color: #1a73e8;
      }
    }
  }
}
</style>
